import {AppBar, Box, IconButton, Toolbar} from "@mui/material";
import {TitleLogo} from "./TitleLogo";
import React, {FC} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Dashboard, Menu} from "@mui/icons-material";
import {modalActions} from "../../app/slices/modalSlice";
import {useAuth0} from "@auth0/auth0-react";

export const RecepoAppBar: FC<{isSide: boolean}> = ({isSide}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth0();
    return (
        <AppBar position={"static"} sx={{minHeight: "80px"}}>
            <Toolbar component={"nav"} sx={{ height:"80px" }}>
                <Box sx={{flexGrow: 1}}>
                    { isAuthenticated && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={ () => dispatch(modalActions.open("drawer")) }
                        >
                            <Menu fontSize={"large"}/>
                        </IconButton>
                    )}
                </Box>
                <Box sx={{flexGrow: 1}} >
                    <TitleLogo />
                </Box>
                <Box>
                    { isSide && (
                        <button onClick={() => navigate("/home")} type="button">
                            <img src="./images/icon_home.svg" alt="" width="32" height="31" decoding="async"/>
                        </button>
                    )}
                </Box>
            </Toolbar>
        </AppBar>)
}