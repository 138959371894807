import React, {FC, MouseEventHandler} from "react";
import {DateSearchToggleBar} from "../DateSeachToggle";
import OperationList from "../organisms/OperationList";
import {DatePickerModal} from "../modals/DatePickerModal";
import {useDispatch, useSelector} from "react-redux";
import {
    DateAfterSelector,
    DateBeforeSelector, OperationSearchConditionSelector,
    setFromDate, setSearchText, setToDate, toggleSort, useInsuranceInformationDownloadMutation
} from "../../app/slices/operationSearchConditionSlice";

import {useNavigate, Link} from "react-router-dom";

import { modalActions} from "../../app/slices/modalSlice";
import {
    useOperationCsvDownloadMutation,
    useOperationListApiQuery
} from "../../store/api";
import {default as fileDownload} from "js-file-download";
import {
    Backdrop, Box, Button,
    CircularProgress, Container, Grid,
    Stack, Typography,
} from "@mui/material";
import {CircleButton} from "../atoms/Buttons";
import {CsvImportModal} from "../modals/CsvImportModal";
import {Add, Download, Print, UploadFile} from "@mui/icons-material";
import {SearchTextInput} from "../atoms/TextFields";
import {FeatureFlag} from "../atoms/FeatureFlagF";
import {RecepoAppBar} from "../atoms/RecepoAppBar";

type OperationsProps = {
    className: "list" | "m-splitColumns";
    isSmall: boolean;
}

const ListSearchPanel = () => {
    const dispatch  = useDispatch();
    const navigate = useNavigate();

    const condition = useSelector(OperationSearchConditionSelector);

    const after = useSelector(DateAfterSelector);
    const before = useSelector(DateBeforeSelector);

    const handleCsvImportOpen = () => {
        dispatch(modalActions.open("csvImport"));
    }

    const [downloadMutation] = useOperationCsvDownloadMutation()

    const { startDownload } = useInsuranceInformationDownloadMutation();

    // ファイルダウンロードボタン
    const handleDownloadCsv = () => {
        const response = downloadMutation({
            keyword: condition.searchText,
            startDate: condition.fromDate,
            endDate: condition.toDate,
        });

        response.then( (csv: unknown) => {
            const text = csv as {data : string}

            const blob = new Blob([text.data], {type: 'text/plain'});

            fileDownload(blob, "operations.csv");
        });
    }

    return (
        <>
            <Box width={400}>
                <SearchTextInput
                    fullWidth
                    onSearch={(text) => dispatch(setSearchText(text))}/>
            </Box>

            <Grid marginTop={4} container gap={5} alignItems={"center"}>
                <Grid item>
                    <Box width={400}>
                        <DateSearchToggleBar className={`list`}/>
                    </Box>
                </Grid>

                <Grid item>
                    <Stack direction={"row"} gap={2} alignItems={"center"}>
                        <Button variant={"outlined"}
                                onClick={() =>
                                    dispatch(modalActions.open("beforeDateDialog"))}>
                            <Typography color={"primary"}>{before}</Typography>
                        </Button>
                        <Typography color={"primary"} fontSize={"large"} fontWeight={"600"}>〜</Typography>
                        <Button variant={"outlined"}
                                onClick={() =>
                                    dispatch(modalActions.open("beforeDateDialog"))}>
                            <Typography color={"primary"}>{after}</Typography>
                        </Button>
                        <Typography color={"primary"} fontSize={"large"} fontWeight={"600"}>を表示</Typography>

                        <Box>
                            <div className={`list__entry`} style={{display: "inline-block", width: "260px"}}>
                                <div style={{display: "inline-block", verticalAlign: "top"}}>
                                    <CircleButton onClick={() => handleCsvImportOpen()} name={"CSV連携"}>
                                        <UploadFile sx={{fontSize: "2.8rem"}}/>
                                    </CircleButton>
                                </div>
                                <div style={{display: "inline-block", verticalAlign: "top", paddingLeft: "5px"}}>
                                    <CircleButton onClick={() => {
                                        startDownload()
                                    }} name={(<>保険情報<br/>印刷</>)}>
                                        <Print sx={{fontSize: "3rem"}}/>
                                    </CircleButton>
                                </div>
                                <div onClick={() => {
                                    navigate("/operation/new")
                                }}
                                     style={{display: "inline-block", verticalAlign: "top", paddingLeft: "5px"}}>
                                    <CircleButton onClick={() => navigate("/operation/new")} name={"手術登録"}>
                                        <Add sx={{fontSize: "3rem"}}/>
                                    </CircleButton>
                                </div>
                                <div style={{display: "inline-block", verticalAlign: "top", paddingLeft: "5px"}}>
                                    <CircleButton onClick={handleDownloadCsv} name={(<>csv<br/>Download</>)}>
                                        <Download sx={{fontSize: "3rem"}}/>
                                    </CircleButton>
                                </div>
                            </div>
                        </Box>

                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

const SidePaneSearchPanel = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const after = useSelector(DateAfterSelector);
    const before = useSelector(DateBeforeSelector);

    const {startDownload} = useInsuranceInformationDownloadMutation();

    return (
        <>
            <Stack direction={"row"}>

                <Box width={"100%"} maxWidth={800} paddingRight={"20px"}>
                    <SearchTextInput
                        fullWidth
                        onSearch={(text) => dispatch(setSearchText(text))}/>
                </Box>

                <Box width={200}>

                    <div style={{display: "inline-block", verticalAlign: "top"}}>
                        <FeatureFlag name={"INSURANCE"}>
                            <CircleButton onClick={() => {
                                startDownload()
                            }} name={(<>保険情報<br/>印刷</>)}>
                                <Print sx={{fontSize: "3rem"}}/>
                            </CircleButton>
                        </FeatureFlag>
                    </div>

                    <div style={{display: "inline-block", paddingLeft: "10px", verticalAlign: "top"}}>
                        <CircleButton onClick={() => {
                            navigate("/operation/new")
                        }} name={"手術登録"}>
                            <Add sx={{fontSize: "3rem"}}/>
                        </CircleButton>
                    </div>

                </Box>
            </Stack>

            <Box marginTop={4}/>

            <DateSearchToggleBar className={`m-splitColumns`}/>

            <Box marginTop={4}>

            </Box>
            <Box width="100%">
                <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <Button variant={"outlined"}
                            onClick={() =>
                                dispatch(modalActions.open("beforeDateDialog"))}>
                        <Typography color={"primary"}>{before}</Typography>
                    </Button>
                    <Typography color={"primary"} fontSize={"large"} fontWeight={"600"}>〜</Typography>
                    <Button variant={"outlined"}
                            onClick={() =>
                                dispatch(modalActions.open("beforeDateDialog"))}>
                        <Typography color={"primary"}>{after}</Typography>
                    </Button>
                    <Typography color={"primary"} fontSize={"large"} fontWeight={"600"}>を表示</Typography>
                </Stack>
            </Box>

            <Box marginTop={3}/>

        </>
    );
}

export const Operations: FC<OperationsProps> = (props) => {
    console.log("Operations")

    const dispatch = useDispatch();

    const condition = useSelector(OperationSearchConditionSelector);
    const {isFetching} = useOperationListApiQuery({
        keyword: condition.searchText,
        startDate: condition.fromDate,
        endDate: condition.toDate,
        isAsc: condition.sortAsc
    })

    const after = useSelector(DateAfterSelector);
    const before = useSelector(DateBeforeSelector);

    const onChangeDateBefore = (start: string, end: string, ) => {
        dispatch(setFromDate((start ?? '') as string))
        dispatch(setToDate((end ?? '') as string))
    }

    return (
        <div className={ props.isSmall ? "side" : "" }>
            <RecepoAppBar isSide={props.isSmall}/>

            <Container maxWidth={"xl"}>
                <Box marginTop={5}>
                    {props.className === "m-splitColumns" ?
                        (<SidePaneSearchPanel/>) :
                        (<ListSearchPanel/>)}
                </Box>

                <OperationList className={ props.className === "m-splitColumns" ? "m-listItem" : "list" }
                               isSmall={props.isSmall} />

            </Container>

            <CsvImportModal dialogName={"csvImport"}/>

            <DatePickerModal
                dialogName={"beforeDateDialog"}
                DefaultStartValue={before}
                DefaultEndValue={after}
                onChange={onChangeDateBefore}
                title={"検索期間（開始日）"}
            />

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isFetching}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>)

}