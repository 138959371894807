import React, {FC, MouseEventHandler } from "react";
import {Box, BoxProps, Grid, GridSize, Typography} from "@mui/material";

export const MList: FC<{ className: "list" | "m-listItem" }> = ({className, children}) => {
    return (
        <div className={ className + "__data" } >
            {children}
        </div>)
}

export const MListFilter: FC<{ onClick: MouseEventHandler, isAsc: boolean, className: "list" | "m-listItem" }> = ({className, onClick, isAsc, children}) => {
    const imageUrl = "./images/icon_arrow_down.svg"
    const style = isAsc ? { transform: "rotate(180deg)" } : {} ;

    return (
        <div className={ (className === "m-listItem" ? "m-splitColumns" : "list") + "__bar" } onClick={onClick}>
            <div className={ (className === "m-listItem" ? "m-splitColumns" : "list") + "__sort" }>
                <button>
                    <img src="./images/icon_sort.svg" alt="" width="17" height="17" decoding="async" />
                    日付で並び替え <img src={imageUrl} style={style} alt="" width="12" height="7" decoding="async" />
                </button>
            </div>
            <button><img src="./images/icon_filter.svg" alt="" width="23" height="21" decoding="async" /></button>
        </div>)
}

export const MListItem: FC<BoxProps> = (props) => {
    const { children, ...boxProp} = props;
    return (
        <Box { ...boxProp }
             sx={{
            "borderWidth": "2px",
            "borderStyle": "solid",
            "borderColor": "#034c8c",
            "borderRadius": "5px",
            padding: "6px 13px 7px",
            marginBottom: "3px",
            ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.05)", // theme.shadows[20]
                boxShadow: 3, // theme.shadows[20]
                cursor:"pointer"
            },
             "&.active": {
                 background: "#e8e8e8"
             }
        }}>
            {props.children}
        </Box>)
}

export const MListItemDate:FC<{date: string}> = (props) => (
    <div className="m-listItem__date">{props.date}</div>
)

export const MListItemColumn:FC<{title: string, data: string, xs?: GridSize , sm?: GridSize , md?: GridSize, lg?: GridSize }> = (props) => (
    <Grid item xs={props.xs} sm={props.sm} md={props.md} lg={props.lg}>
        <Grid item>
            <Typography sx={{ fontSize: 13 }} color="#034c8c" gutterBottom>
                {props.title}
            </Typography>
        </Grid>
        <Grid item>
            <Typography sx={{ fontSize: 16, fontWeight: "bold"  }} color="#034c8c" gutterBottom>
                { props.data }
            </Typography>
        </Grid>
    </Grid>)
