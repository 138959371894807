import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type StateType = {
    modalName: string
};

export const initialState: StateType = {
    modalName: ""
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        open(state, action: PayloadAction<string>) {
            return { ...state, modalName: action.payload }
        },
        close(state) {
            return { ...state, modalName: "" }
        },
    },
});

export const modalActions = modalSlice.actions;
export const isOpen = (name: string) => (state: RootState) => state.modal.modalName === name
// export const { editedOperation, selectedOperation } = operationSlice.actions;
// export const selectOpItems = (state: RootState) => state.opItems.opItems;

export const modalReducer = modalSlice.reducer;
