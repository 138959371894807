import React, {FC, useState} from "react";
import {useInsuranceTabParam, useOperationIdParam, useShugiTabParam} from "../../hooks/useOperationIdParameter";

import {useNavigate} from "react-router-dom";
import {
    Box, Checkbox,
    Container, styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup, Typography
} from "@mui/material";
import {
    OperationItems,
    useDepartmentListApiQuery,
    useDoctorListApiQuery,
    useGetOperationDetailApiQuery, useGetOperationItemQuery,
    useGetOperationShugiQuery
} from "../../store/api";
import dayjs from "dayjs";

const StyledItemTableRow = styled(TableRow)(({ theme }) => ({

    '&.dark': {
        // backgroundColor: theme.palette.action.hover,
        backgroundColor: "rgba(10, 10, 10, 0.07)",
    },

    // '&:nth-child(4n-1)': {
    //     // backgroundColor: theme.palette.action.hover,
    //     backgroundColor: "rgba(10, 10, 10, 0.07)",
    // },


    // hide last border
    '& td:not(.blank)': {
        border: "solid 1px",
        borderColor: theme.palette.text.primary
    },
    '& td.blank': {
        border: "none",
        backgroundColor: "#f8f8f8",
    },
}));

const StyledShugiTableRow = styled(TableRow)(({ theme }) => ({
    '&  input:disabled': {
        background: "red",
        color: "blue",
            // "-webkit-appearance":"none",
            // "-moz-appearance":"none",
            // "appearance":"none",
    },

    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(10, 10, 10, 0.07)",
    },

    // hide last border
    '& td:not(.blank)': {
        border: "solid 1px",
        borderColor: theme.palette.text.primary
    },
    '& td.blank': {
        border: "none",
    },
}));

const StyledTableHeader = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: rgba(10, 10, 10, 0.08);
    // },

    height: "0.1rem",
    // hide last border
    '& th': {
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.text.primary),
        border: "solid 1px red",
        borderColor: theme.palette.primary.dark,
        lineHeight: "0.8rem"
    },
}));

const InsuranceTableHeader = () => {

    let operationId = useOperationIdParam();

    const { data : operation} = useGetOperationDetailApiQuery({ opId: operationId });
    const { data : opshugis } = useGetOperationShugiQuery({ opId: operationId });
    const { data : departments} = useDepartmentListApiQuery();
    const { data : doctors} = useDoctorListApiQuery();

    if (operation == null) return (<></>);
    if (opshugis == null) return (<></>);
    if (departments == null) return (<></>);
    if (doctors == null) return (<></>);


    return (
        <>
            <Box textAlign={"right"} marginTop={5}>
                <Typography color={theme => theme.palette.text.primary} fontSize={"large"}>症例日： { dayjs(operation.operationDate).format("YYYY年MM月DD日") }</Typography>
            </Box>

            <Table>
                <TableHead>
                    <StyledTableHeader>
                        <TableCell>部署名</TableCell>
                        <TableCell>診療科</TableCell>
                        <TableCell>執刀医</TableCell>
                        <TableCell>OP ID</TableCell>
                        <TableCell>術式コード</TableCell>
                        <TableCell>レセ電コード</TableCell>
                        <TableCell>術式名</TableCell>
                    </StyledTableHeader>
                </TableHead>
                <TableBody>
                    { opshugis.operationShugis.map( (opShugi, i) => {
                        return (
                            <StyledItemTableRow className={(i + 1) % 2 == 0 ? "dark" : "" } key={i} >
                                { i == 0 ? (
                                    <>
                                        <TableCell>{ operation.responsibleDivision }</TableCell>
                                        <TableCell>{ [...(departments.filter( (d) => d.id === operation.departmentId )) , ...[ { name: "" } ]][0].name  }</TableCell>
                                        <TableCell>{ [...(doctors.filter( (d) => d.id === operation.doctorId )) , ...[ { name: "" }]][0].name }</TableCell>
                                        <TableCell>{ operation.externalOpId }</TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell className={"blank"}></TableCell>
                                        <TableCell className={"blank"}></TableCell>
                                        <TableCell className={"blank"}></TableCell>
                                        <TableCell className={"blank"}></TableCell>
                                    </>
                                )}
                                <TableCell>{opShugi.shugi.kubun}</TableCell>
                                <TableCell>{opShugi.shugi.code}</TableCell>
                                <TableCell>{opShugi.shugi.name}</TableCell>
                            </StyledItemTableRow>
                            );
                    })}
                </TableBody>
            </Table>
        </>
    );
}

const InsutanceKasans: FC = () => {
    let operationId = useOperationIdParam();

    const { data : operation} = useGetOperationDetailApiQuery({ opId: operationId });
    const { data : opshugis } = useGetOperationShugiQuery({ opId: operationId });

    if (opshugis == null) return (<></>);
    if (operation == null) return (<></>);

    return (
        <Container>
            <InsuranceTableHeader />
            <Typography color={theme => theme.palette.text.primary} fontSize={"x-large"}>加算項目</Typography>

            { opshugis.operationShugis.map( (ops, i) => {
                return (
                    <Box marginBottom={5} key={i}>
                        <Table sx={{"width": "700px"}}>
                            <TableHead >
                                <StyledTableHeader>
                                    <TableCell>術式コード</TableCell>
                                    <TableCell >レセ電コード</TableCell>
                                    <TableCell >術式名</TableCell>
                                </StyledTableHeader>
                            </TableHead>
                            <TableBody>
                                <StyledItemTableRow>
                                    <TableCell >{ops.shugi.kubun}</TableCell>
                                    <TableCell >{ops.shugi.code}</TableCell>
                                    <TableCell >{ops.shugi.name}</TableCell>
                                </StyledItemTableRow>
                            </TableBody>
                        </Table>
                        <Table>
                            <TableHead>
                                <StyledTableHeader>
                                    <TableCell >チェック</TableCell>
                                    <TableCell >加算名</TableCell>
                                    <TableCell >レセ電コード</TableCell>
                                    <TableCell >バーコード</TableCell>
                                    <TableCell >金額</TableCell>
                                </StyledTableHeader>
                            </TableHead>
                            <TableBody>
                                { ops.kasans.map((opKasans) => {
                                    return (
                                        <StyledShugiTableRow key={opKasans.id}>
                                            <TableCell >{
                                                opKasans.isUsed ?
                                                    (<Checkbox checked />) : (<Checkbox checked={false} />)
                                            }</TableCell>
                                            <TableCell >{opKasans.kubun} {opKasans.name}</TableCell>
                                            <TableCell >{opKasans.receptCode}</TableCell>
                                            <TableCell ></TableCell>
                                            <TableCell align={"right"} > { opKasans.revenue?.toLocaleString() }円</TableCell>
                                        </StyledShugiTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                )
            })}
        </Container>
    )
}

const InsutanceItem: FC = () => {
    let operationId = useOperationIdParam();

    const { data : operation} = useGetOperationDetailApiQuery({ opId: operationId });
    const { data : opshugis } = useGetOperationShugiQuery({ opId: operationId });
    const { data : opItems } = useGetOperationItemQuery({ opId: operationId });

    if (operation == null) return (<></>);

    if (opItems == null) return (<></>);
    if (opshugis == null) return (<></>);


    const targetOpItems: Array< OperationItems & { typeName: string }> = opItems.map((opItem) => {
        let target = null;
        if (opItem.yakuhinId != null) {
            // 薬品は表示する
            target = { ...opItem, typeName: "薬価" }

        } else if (opItem.seikyuNaiyou == "条件償還") {
            target = { ...opItem, typeName: "条件付き償還" }
        } else if (opItem.seikyuNaiyou == "償還価格") {
            target = { ...opItem, typeName: "償還" }
        } else {
            opshugis.operationShugis.map((shugi) => {
                shugi?.kasans.forEach((kasan) => {
                    console.log(kasan)
                    kasan?.relatedOperationItems?.forEach((opi) => {
                        if (opItem.itemId == opi?.id) {
                            target = {...opItem, typeName: "加算"}
                        }
                    });
                });

                shugi?.relatedOperationItems?.forEach((opi) => {

                    if (opItem.itemId == opi?.id) {
                        target = {...opItem, typeName: "保険"}
                    }
                });
            })
        }

        return target;
    }).filter( (e) => e != null ) as Array< OperationItems & { typeName: string }>


    return (
        <Container>
            <InsuranceTableHeader/>

            <Box marginTop={5}>
                <Table>
                    <TableHead>
                        <StyledTableHeader>
                            <TableCell>材料コード</TableCell>
                            <TableCell>商品名</TableCell>
                            <TableCell>カタログ番号</TableCell>
                            <TableCell>保険の種類</TableCell>
                            <TableCell>医事コード</TableCell>
                            <TableCell >数量</TableCell>
                            <TableCell >金額</TableCell>
                        </StyledTableHeader>
                        <StyledTableHeader>
                            <TableCell colSpan={3}>レセ電コード</TableCell>
                            <TableCell colSpan={4}>保険材料名</TableCell>
                        </StyledTableHeader>
                    </TableHead>
                    <TableBody>
                        {targetOpItems.map((item, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <StyledItemTableRow  className={(i + 1) % 2 == 0 ? "dark" : "" } >
                                        <TableCell>{item.itemCode}</TableCell>
                                        <TableCell>{item.itemName}</TableCell>
                                        <TableCell>{item.clinicCode}</TableCell>
                                        <TableCell>{item.typeName }</TableCell>
                                        <TableCell>(仮)</TableCell>
                                        <TableCell align={"right"}>{ item.quantity }</TableCell>
                                        <TableCell align={"right"}>{ item.amount.toLocaleString() }円</TableCell>
                                    </StyledItemTableRow>
                                    { item.typeName == "薬価" && (
                                        <StyledItemTableRow  className={(i + 1) % 2 == 0 ? "dark" : "" } >
                                            <TableCell colSpan={3}>{item.yakuhin_receipt_code}</TableCell>
                                            <TableCell colSpan={4}>{item.yakuhin_receipt_name}</TableCell>
                                        </StyledItemTableRow>
                                    )}
                                    { item.typeName != "薬価" && item.tokuteis.length == 0 ? (
                                        <StyledItemTableRow  className={(i + 1) % 2 == 0 ? "dark" : "" } >
                                            <TableCell colSpan={3}>　</TableCell>
                                            <TableCell colSpan={4}>　</TableCell>
                                        </StyledItemTableRow>) : item.tokuteis.map( (tokutei, j) => {
                                            return (
                                                <StyledItemTableRow  className={(i + 1) % 2 == 0 ? "dark" : "" } key={j} >
                                                    <TableCell colSpan={3}>{tokutei.code}</TableCell>
                                                    <TableCell colSpan={4}>{tokutei.name}</TableCell>
                                                </StyledItemTableRow>
                                            )
                                        })
                                    }
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </Box>

        </Container>
    );
}

const useTabComponent = (tab: "item"|"kasans") => {
    if (tab == "item") {
        return (<InsutanceItem />)
    } else {
        return (<InsutanceKasans />);
    }

}

const InsuranceInformation: React.FC = (props) => {
    let operationId = useOperationIdParam();
    let tab = useInsuranceTabParam();
    let navigate = useNavigate();
    let content = useTabComponent (tab);

    const handleSelectShugiTab = (value: string) => {
        navigate(`/operation/${operationId}/mci_info/${value}`)
    }

    return (
        <React.Fragment>
            <Box sx={{
                "display": "block",
                "overflowY": "scroll",
                "height": "calc(100vh - 80px)"
            }}>
                <Box marginTop={"40px"} display={"flex"} width={"100%"} justifyContent={"center"}>
                    <Box width={"400px"}>
                        <ToggleButtonGroup
                            value={tab}
                            fullWidth
                            exclusive
                            onChange={ (e, v) => handleSelectShugiTab(v) }
                            aria-label="text alignment"
                        >
                            <ToggleButton value="item" aria-label="left aligned">
                                使用材料
                            </ToggleButton>
                            <ToggleButton value="kasans" aria-label="left aligned">
                                加算項目
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Box>
                {content}
            </Box>
        </React.Fragment>
    );
};

export default InsuranceInformation;