import {InputAdornment, TextField} from "@mui/material";
import React, {FC, useState} from "react";
import {Search} from "@mui/icons-material";
import {TextFieldProps} from "@mui/material/TextField/TextField";


export type onSearchEventHandler = (searchText: string) => void;

export const SearchTextInput: FC<{
    onSearch: onSearchEventHandler } & TextFieldProps> = (props) => {
    const { onSearch, ...textFieldProps } = props;
    const [ searchText, setSearch ] = useState("");

    return (
        <TextField
            variant="filled"
            label="Search"
            type="search"
           color={"success"}
           value={searchText}
            { ...textFieldProps }

            onChange={ (e) => setSearch(e.target.value) }
               onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                   if (e.key === "Enter") {
                       onSearch(searchText)
                   }
               }}

               InputProps={{
                   endAdornment: (
                       <InputAdornment position="start">
                           <Search />
                       </InputAdornment>
                   ),
               }} />
)
}