import {useParams} from "react-router-dom";
import {ShugiType} from "../store/api";
import {useLocation} from "react-router-dom";

type ShugiTabList = "shujutsu" | "masui" | "yuketsu"
type InsutanceTabList = "item" | "kasans"

export const useContentTabIdIdParam : () => string = () => {
    return window.location.pathname.split("/")[3] ?? "detail";
}

export const useHasOperationId : () => boolean = () => {
    let { operationId } = useParams<{operationId: string}>();

    return operationId != null;
}

export const useOperationIdParam : () => number = () => {
    let { operationId } = useParams<{operationId: string}>();

    return Number.parseInt(operationId ?? "0");
}

export const useShugiTabParam : () => ShugiTabList = () => {
    let { shugiTab } = useParams<{shugiTab: string}>();

    if (shugiTab === "shujutsu" || shugiTab === "masui" || shugiTab === "yuketsu") {
        return shugiTab;
    } else {
        return "shujutsu";
    }
}

export const useInsuranceTabParam : () => InsutanceTabList = () => {
    let { mciTab } = useParams<{mciTab: string}>();

    if (mciTab === "item" || mciTab === "kasans") {
        return mciTab;
    } else {
        return "item";
    }
}

export const useShugiListTypeParam: () => ShugiType | null = () => {
    let { shugiListType } = useParams<{shugiListType: string}>();

    if ( shugiListType === "shugi" || shugiListType === "shochi" || shugiListType === "kensa" ||
         shugiListType === "masui" || shugiListType === "yuketsu"  || shugiListType === "yuketsu-kensa") {
        return shugiListType;
    } else {
        return null;
    }
}