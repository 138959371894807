import {FC} from "react";
import {MListItem} from "./MListItemColumn";
import {BoxProps} from "@mui/material";
import {useSelectOperation} from "../../app/slices/operationSlice";
import {Link} from "react-router-dom";

export const NavigativeMListItem :FC<BoxProps & { operationId: number; } > = (props) => {
    console.log("NavigativeMListItem")

    const { operationId, children, ...boxProps } = props

    const selectOperation = useSelectOperation();

    return (
        <Link to={"/operation/" + operationId + "/detail"} style={{ textDecoration: "none" }}>
            <MListItem {...boxProps}
                       onClick={ () => {
                           // IDを選択する
                           selectOperation( props.operationId ) }
                       }
            >
                {children}
            </MListItem>
        </Link>
    );
}