import {api, OperationCsvDownloadApiArg, useOperationPrintApiMutation, useReorderOperationShugiMutation} from "./api";
import {FetchArgs} from "@reduxjs/toolkit/query";
import {CustomReorderOperationShugiAPI} from "../app/slices/OpShugis";
import {CustomGetBalanceAPI, CustomSetOperationItemCaluculationStatusAPI} from "../app/slices/balanceSlice";

export const applyCustomAPI = () => {
    api.enhanceEndpoints({
        endpoints: {
            operationCsvDownload(endpoint) {
                if (endpoint.query != null) {
                    let qeury: (arg: OperationCsvDownloadApiArg) => string | FetchArgs = endpoint.query
                    endpoint.query = (arg: OperationCsvDownloadApiArg): string | FetchArgs => {
                        const res = qeury(arg);

                        if ( "string" == typeof res)  {
                            return res;
                        } else {
                            return {
                                ...res,
                                responseHandler: (r: any) => r.text()
                            }
                        }
                    }
                }
            },
            operationPrintApi(endpoint) {
                if (endpoint.query != null) {
                    let qeury: (arg: OperationCsvDownloadApiArg) => string | FetchArgs = endpoint.query
                    endpoint.query = (arg: OperationCsvDownloadApiArg): string | FetchArgs => {
                        const res = qeury(arg);

                        if ( "string" == typeof res)  {
                            return res;
                        } else {
                            return {
                                ...res,

                                responseHandler: async (response) => {
                                    return await response.blob();
                                },
                            }
                        }
                    }
                }
            },

            reorderOperationShugi: CustomReorderOperationShugiAPI,

            // OptimisticUpdate
            solveWarning: CustomGetBalanceAPI,

            setOperationItemCaluculationStatus: CustomSetOperationItemCaluculationStatusAPI,

            deleteOperationShugi(endpoint) {
                // OptimisticUpdate
                endpoint.onQueryStarted = async (request, { dispatch, queryFulfilled }) => {
                    const patchResult = dispatch(
                        api.util.updateQueryData('getOperationShugi', {opId: request.opId} , (draft) => {
                            draft.operationShugis = draft.operationShugis.filter( (opShugis) => opShugis.id != request.opShugiId )
                        })
                    )
                    try {
                        const { data: updatedPost } = await queryFulfilled;
                    } catch (e) {

                    }
                }
            }
        }
    });
}
