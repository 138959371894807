import {FC, PropsWithChildren, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedOperation,
  editSelectedOperationsBasicInfo,
  selectEditedOperation
} from '../../app/slices/operationSlice';
import { useForm, Controller } from 'react-hook-form';
import {
    OperationResult,
    useDepartmentListApiQuery,
    useDoctorListApiQuery, useIsExternalIdExistsMutation
} from "../../store/api";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {Grid, Input, MenuItem, Select, TextField, Typography} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ja from 'date-fns/locale/ja'
import dayjs from "dayjs";

const BasicInfoFormCell: FC<{iconUrl: string, label: string, errorMessage: string, error: boolean}> = (props) => {
    return (<div className="m-splitColumns__input" style={ props.error ? styles.errorStyle : {} }>
        <div className="m-splitColumns__label">
            <div><img src={props.iconUrl} style={ props.error ? styles.imageErrorStyle : {} } alt="" width="26" height="30"/></div>
            <div style={ props.error ? styles.errorStyle : {} }>{props.label} { props.error && (<small>{props.errorMessage}</small>)}</div>
        </div>
        <div className="input">
            {props.children}
        </div>
    </div>)
}

const BasicInfoForm: React.FC<{
    onSave: () => Promise<void>,
    editedOperation: OperationResult
}> = (props) => {
    const selectOperation = useSelector(selectSelectedOperation);
    const { data: departments } = useDepartmentListApiQuery();
    const { data: doctors } = useDoctorListApiQuery();

    const filteredDoctors = (doctors??[]).filter( d => props.editedOperation.departmentId == 0 || d.department_id == props.editedOperation.departmentId );
    const dispatch = useDispatch();
    const [isShowOperationDateModal, setIsShowOperationModal] = useState(false);
    const [ isExternalIdExists ] = useIsExternalIdExistsMutation();
    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        watch
    } = useForm({
        mode: 'onBlur',
        reValidateMode:"onSubmit",
        defaultValues: {
            externalOpId: props.editedOperation.externalOpId,
            operationDate: (dayjs(props.editedOperation.operationDate).isValid() ? dayjs(props.editedOperation.operationDate ) : dayjs() ).format("YYYY-MM-DD"),
            startTime: props.editedOperation.startTime ?? "",
            departmentId: props.editedOperation.departmentId,
            doctorId:  props.editedOperation.doctorId,
            responsibleDivision: props.editedOperation.responsibleDivision,
            memo: props.editedOperation.memo,
        },
  });

  useEffect( () => {
      setValue("externalOpId", selectOperation.externalOpId)
      setValue("operationDate", (dayjs(selectOperation.operationDate).isValid() ? dayjs(selectOperation.operationDate ) : dayjs() ).format("YYYY-MM-DD"))
      setValue("startTime", selectOperation.startTime ?? "")
      setValue("departmentId", selectOperation.departmentId)
      setValue("doctorId", selectOperation.doctorId  ?? 0)
      setValue("responsibleDivision", selectOperation.responsibleDivision)
      setValue("memo", selectOperation.memo)
  },[selectOperation])

    useEffect( () => {
        if (departments != null && doctors != null && filteredDoctors
          .filter( d => d.id == props.editedOperation.doctorId )
          .filter( d => d.department_id == props.editedOperation.departmentId ).length == 0) {
            setValue("doctorId", 0)
        }
    },[props.editedOperation.departmentId, departments, doctors])

    if (departments == null) {
        return (<></>);
    }

    if (doctors == null) {
        return (<></>);
    }

    const onSubmit = () => {
        dispatch(editSelectedOperationsBasicInfo(getValues()))
        props.onSave()
    };

    watch( () => {
        const formValues = getValues();
        if (formValues.operationDate != null) {
            dispatch(editSelectedOperationsBasicInfo(formValues))
        }
    })

    const sx = () => ({
        "& fieldset": {
            borderWidth: "0",
            borderStyle: "none",
        },
        input: {
            fontFamily: "sans-serif",
            height: "50px",
            padding:"0",
        },
    })

    return (
      <div className="m-splitColumns__main">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="m-splitColumns__form"  >
            <Controller
                control={control}
                name='operationDate'
                rules={{ required: "※ 手術日は必須入力です", validate: (value) => {
                    if ( ! dayjs(value).isValid() )
                        return "※ YYYY-MM-DD形式で入力してください";
                    return true;
                    } }}
                render={({ field, fieldState,formState }) =>
                    (<BasicInfoFormCell label={"手術日"}
                                        iconUrl={"./images/icon_calendar.svg"}
                                        error={!!fieldState.error }
                                        errorMessage={ fieldState.error?.message ?? "" }>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja} >
                            <DesktopDatePicker
                                renderInput={(props) => (
                                    <TextField { ...props} sx={ sx } error={ !!fieldState.error } />)}
                                onChange={(e) => { field.onChange(e ? dayjs(e).format("YYYY-MM-DD") : "" ); setIsShowOperationModal(false)}}
                                value={field.value}/>
                        </LocalizationProvider>
                    </BasicInfoFormCell>
                )}/>

              <Controller control={control}
                          name={"startTime"}
                          rules={{ required: true }}

                          render={ ({field, fieldState}) => (
                              <BasicInfoFormCell label={"開始予定時間"}
                                                 iconUrl={"./images/icon_clock.svg"}
                                                 error={!!fieldState.error}
                                                 errorMessage={"※開始予定時間は必ず入力してください"}>
                                  <TextField
                                      type="time"
                                      fullWidth
                                      data-testid={"startTime"}
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                      onChange={field.onChange}
                                      onBlur={field.onBlur}
                                      ref={field.ref}
                                      value={field.value}
                                      sx={ sx }
                                  />
                              </BasicInfoFormCell>)}
              />

          <Controller
              control={control}
              name='externalOpId'
              rules={{ validate: (value) => {
                      if (value == "" || value == null) {
                          return "※OP IDは必ず入力してください";
                      }

                      if (selectOperation.externalOpId == value)
                          return true;

                      return isExternalIdExists({ body: {externalOpId: value} }).then( (e) => {
                          if ( 'data' in e) {
                              return e.data ? "※ OPIDはすでに使用されています。" : true
                          } else {
                              return "※ システムエラーが発生しました。"
                          }
                      })
                  }  }}
              render={({ field, fieldState}) => (
                  <BasicInfoFormCell label={"OP ID"}
                                     iconUrl={"./images/icon_id.svg"}
                                     error={!!fieldState.error}
                                     errorMessage={fieldState.error?.message ?? ""}>
                      <TextField
                            {...field} sx={ sx }
                            placeholder="123456"
                             onChange={field.onChange}
                             onBlur={field.onBlur}
                             ref={field.ref}
                             value={field.value}/>
                  </BasicInfoFormCell>)} />

          <Controller
              control={control}
              name='responsibleDivision'
              rules={{ validate: (value) => {
                  if (getValues()["responsibleDivision"] == "") {
                      return true;
                  }

                  return true;
              } }}
              render={({ field,fieldState }) => (
                  <BasicInfoFormCell label={"実施部署"}
                                     iconUrl={"./images/icon_calendar.svg"}
                                     error={!!fieldState.error}
                                     errorMessage={　fieldState.error?.message ?? ""　}>
                      <TextField {...field} sx={ sx }
                                 onChange={field.onChange}
                                 onBlur={field.onBlur}
                                 ref={field.ref}
                                 value={field.value}
                      />
                  </BasicInfoFormCell>
              )}/>

          <Controller
                control={control}
                name='departmentId'
                rules={{ validate: (value) => {
                        if (value == 0)
                            return "※ 診療科は必ず指定してください。";
                        return true;
                    }
                }}
                render={({ field,fieldState,formState }) => (
                    <BasicInfoFormCell label={"診療科"}
                                       iconUrl={"./images/icon_division.svg"}
                                       error={!!fieldState.error}
                                       errorMessage={　fieldState.error?.message ?? ""　}>
                        <Select onChange={field.onChange} value={field.value} onBlur={field.onBlur} ref={field.ref} fullWidth>
                            <MenuItem value='0' sx={{color:'gray'}}>未選択</MenuItem>
                            { (departments ?? []).map( (department) => <MenuItem key={department.id} value={department.id}>{department.name}</MenuItem>) }
                        </Select>
                    </BasicInfoFormCell>
                    )}
              />

          <Controller
                control={control}
                name='doctorId'
                rules={{ validate: (value) => {
                        return true;
                    }
                }}
                render={({ field,fieldState,formState }) => (
                    <BasicInfoFormCell label={"執刀医"}
                                       iconUrl={"./images/icon_doctor.svg"}
                                       error={!!fieldState.error}
                                       errorMessage={　fieldState.error?.message ?? ""　}>
                        <Select onChange={field.onChange} value={field.value} onBlur={field.onBlur} ref={field.ref} fullWidth>
                            <MenuItem value='0' sx={{color:'gray'}}>未選択</MenuItem>
                            { (filteredDoctors ?? []).map( (doctor) => <MenuItem key={doctor.id} value={doctor.id}>{doctor.name}</MenuItem>) }
                        </Select>
                    </BasicInfoFormCell>
                    )} />

          <div className="m-splitColumns__input textarea">
            <div className="m-splitColumns__label"><img src="./images/icon_pencil.svg" alt="" width="30" height="30" />メモ
            </div>
            <Controller
                  control={control}
                  name='memo'
                  rules={{ required: false }}
                  render={({ field }) =>
                      (<textarea name="" id=""
                                 cols={30}
                                 rows={10}
                                 onChange={field.onChange}
                                 onBlur={field.onBlur}
                                 ref={field.ref}
                                 value={field.value ?? "" } />)
                  }
            />
          </div>

          </div>
          <div style={styles.formLayout} >
              <button style={styles.buttonStyle}
                      color='primary'
                      data-testid='edit'
                      type='submit'
              >
                {props.editedOperation.id !== 0 ? '更新' : '保存'}
              </button>
          </div>
        </form>
    </div>
  );
};

export default BasicInfoForm;
const styles = {
  formLayout: {
    padding: "0 20px",
  },
  buttonStyle: {
      "background": "#034c8c",
      "borderRadius": "9px",
      "boxShadow": "0 3px 6px rgb(0 0 0 / 40%)",
      "color": "#fff",
      "display": "block",
      "fontSize": "23px",
      "padding": "23px 20px",
  },
    imageErrorStyle: {
        filter: "hue-rotate( 135deg ) brightness(1.25)"
    },
    errorStyle: { color: "#BF2427", borderColor: "#BF2427" }
}