import React, {FC, MouseEventHandler, useState} from "react";
import {EditorNavItem} from "../atoms/EditorNavItem";
import {useNavigate} from "react-router-dom";
import {Box, Tab, Tabs} from "@mui/material";
import {useContentTabIdIdParam, useHasOperationId, useOperationIdParam} from "../../hooks/useOperationIdParameter";
import {FeatureFlagF} from "../atoms/FeatureFlagF";
import {useSelector} from "react-redux";
import {operationIdNullableSelector, operationIdSelector} from "../../app/slices/operationSlice";

export const EditorNav : FC<{operationId?: number}>= () => {
    let navigate = useNavigate();
    const hasOperationId = useHasOperationId();
    const operationId = useOperationIdParam();
    const tabId = useContentTabIdIdParam();

    const handleOnClick: (e:  React.SyntheticEvent, toTabId:any) => void = (e, toTabId: any) => {
        e?.preventDefault();

        if (hasOperationId) {
           navigate(`${operationId}/${toTabId}`)
        }
    }
    const basicInfoTab = [
        <Tab label="基本情報" value={"detail"} key={"detail"} color={"success"} />
    ]

    const additionalTabs = [
        <Tab label="手術術式" value={"shugis"} key={"shugis"} color={"secondary"}  />,
        <Tab label="使用物品" value={"op_item"} key={"op_item"} color={"secondary"} />,
        <Tab label="保険情報" value={"mci_info"} key={"mci_info"} color={"secondary"} />,
        <Tab label="収支情報" value={"balance"} key={"balance"} color={"secondary"} />
    ];


    return (
        <React.Fragment>
            <Box width={"100%"}>
                <Tabs
                    color={"success"}
                    value={tabId}
                    onChange={ (e,v )=> handleOnClick(e, v)}
                    aria-label="basic tabs example">
                    { [ ...basicInfoTab.concat(hasOperationId ? additionalTabs : []) ] }
                </Tabs>
            </Box>
        </React.Fragment>);
};