import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isOpen, modalActions} from "../../app/slices/modalSlice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography} from "@mui/material";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import dayjs from "dayjs";
import {BadRequestError, OperationSearchResult, useDeleteOperationApiMutation} from "../../store/api";
import {actions as toastActions} from "../../app/slices/ui/toastSlice";


export const DeleteOperationModal: FC<{
    dialogName: string,
    targetId: number
}> = ({dialogName,  targetId}) => {

    const open: boolean = useSelector(isOpen(dialogName));
    const dispatch = useDispatch();

    const [ deleteOperation ] = useDeleteOperationApiMutation();

    const handleDeleteOperation = async () => {
        const result = await deleteOperation({
            opId: targetId
        });

        if ( "error" in result && "status" in result.error ) {
            if (result.error.status == 400) {
                dispatch(
                    toastActions.putToast({
                        kind: "deleteOperation",
                        severity: "error",
                        text: (result.error.data as BadRequestError).message
                    }));
            }
        } else {
            dispatch(
                toastActions.putToast({
                    kind: "deleteOperation",
                    severity: "success",
                    text: "削除しました"
                }));
        }
    }

    const handleClose = () => {
        dispatch(modalActions.close());
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

        >
            <DialogTitle id="alert-dialog-title">
                確認
            </DialogTitle>

            <DialogContent>
                <Typography>
                    削除してよろしいですか？
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleClose }>キャンセル</Button>
                <Button variant={"contained"} color={"error"} onClick={ async () => {
                    await handleDeleteOperation();
                    handleClose();
                }} >削除</Button>
            </DialogActions>
        </Dialog>);

}