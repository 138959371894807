import { Grid, Typography } from "@mui/material";
import React from "react";

export const TitleLogo = () => {

    const envName = process.env.REACT_APP_ENV_NAME;

    const envNameTag = envName != "" ? (
        <Typography 
            color={"white"}
            paddingLeft={1}
            style={{"display": "inline-block", "userSelect": "none", fontSize: "1rem"}}>({envName})</Typography>) : null;

    return (
        <>
            <img src="./images/title.svg"
                 alt=""
                 width="150"
                 height="32"
                 decoding="async"/>
            {envNameTag}
        </>
        // <Grid container
        //       direction="row" justifyContent={"center"} alignItems={"center"} alignContent={"center"}>

        // </Grid>


    )
}