import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "Department",
  "Docutor",
  "Operation",
  "Items",
  "Balance",
  "OpItems",
  "opShugis",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getServiceStatusApi: build.query<
        GetServiceStatusApiApiResponse,
        GetServiceStatusApiApiArg
      >({
        query: () => ({ url: `/v1/appli/status` }),
      }),
      departmentListApi: build.query<
        DepartmentListApiApiResponse,
        DepartmentListApiApiArg
      >({
        query: () => ({ url: `/v1/appli/department` }),
        providesTags: ["Department"],
      }),
      doctorListApi: build.query<DoctorListApiApiResponse, DoctorListApiApiArg>(
        {
          query: () => ({ url: `/v1/appli/doctors` }),
          providesTags: ["Docutor"],
        }
      ),
      operationPrintApi: build.mutation<
        OperationPrintApiApiResponse,
        OperationPrintApiApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/ops:print`,
          method: "POST",
          params: {
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            keyword: queryArg.keyword,
            is_asc: queryArg.isAsc,
          },
        }),
        invalidatesTags: ["Operation"],
      }),
      findItems: build.query<FindItemsApiResponse, FindItemsApiArg>({
        query: (queryArg) => ({
          url: `/v1/appli/items`,
          params: {
            name: queryArg.name,
            offset: queryArg.offset,
            type: queryArg["type"],
          },
        }),
        providesTags: ["Items"],
      }),
      operationListApi: build.query<
        OperationListApiApiResponse,
        OperationListApiApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/ops`,
          params: {
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            keyword: queryArg.keyword,
            is_asc: queryArg.isAsc,
          },
        }),
        providesTags: ["Operation"],
      }),
      postOperationApi: build.mutation<
        PostOperationApiApiResponse,
        PostOperationApiApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/ops`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Operation", "Balance"],
      }),
      operationCsvDownload: build.mutation<
        OperationCsvDownloadApiResponse,
        OperationCsvDownloadApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/ops/csv`,
          method: "POST",
          params: {
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            keyword: queryArg.keyword,
            is_asc: queryArg.isAsc,
          },
        }),
        invalidatesTags: ["Operation"],
      }),
      isExternalIdExists: build.mutation<
        IsExternalIdExistsApiResponse,
        IsExternalIdExistsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/operation:isIdExists`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      getOperationDetailApi: build.query<
        GetOperationDetailApiApiResponse,
        GetOperationDetailApiApiArg
      >({
        query: (queryArg) => ({ url: `/v1/appli/ops/${queryArg.opId}` }),
        providesTags: ["Operation"],
      }),
      putOperationApi: build.mutation<
        PutOperationApiApiResponse,
        PutOperationApiApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/operation/${queryArg.opId}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Operation", "Balance"],
      }),
      deleteOperationApi: build.mutation<
        DeleteOperationApiApiResponse,
        DeleteOperationApiApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/operation/${queryArg.opId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Operation"],
      }),
      getBalance: build.query<GetBalanceApiResponse, GetBalanceApiArg>({
        query: (queryArg) => ({
          url: `/v1/appli/balance`,
          params: { opId: queryArg.opId },
        }),
        providesTags: ["Balance"],
      }),
      validateOperationCsv: build.mutation<
        ValidateOperationCsvApiResponse,
        ValidateOperationCsvApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/operation:validateCsv`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Operation", "Balance"],
      }),
      postOperationImport: build.mutation<
        PostOperationImportApiResponse,
        PostOperationImportApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/operation:importCsv`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Operation", "Balance"],
      }),
      getOperationItem: build.query<
        GetOperationItemApiResponse,
        GetOperationItemApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/opItems`,
          params: { opId: queryArg.opId },
        }),
        providesTags: ["OpItems"],
      }),
      postOperationItem: build.mutation<
        PostOperationItemApiResponse,
        PostOperationItemApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/opItems/new`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["OpItems", "opShugis", "Balance"],
      }),
      postOperationItemWithJancode: build.mutation<
        PostOperationItemWithJancodeApiResponse,
        PostOperationItemWithJancodeApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/opItems/jan`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["OpItems", "Balance"],
      }),
      putOperationItem: build.mutation<
        PutOperationItemApiResponse,
        PutOperationItemApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/opItems/${queryArg.opItemId}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["OpItems", "Balance"],
      }),
      setOperationItemCaluculationStatus: build.mutation<
        SetOperationItemCaluculationStatusApiResponse,
        SetOperationItemCaluculationStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/operation/${queryArg.operationId}/opItem/${queryArg.opItemId}/tokutei/${queryArg.tokuteiId}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["OpItems", "Balance"],
      }),
      putOperationOtherItem: build.mutation<
        PutOperationOtherItemApiResponse,
        PutOperationOtherItemApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/operation/${queryArg.opId}/opOtherItems/${queryArg.opOtherItemId}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["OpItems", "Balance"],
      }),
      deleteOperationItem: build.mutation<
        DeleteOperationItemApiResponse,
        DeleteOperationItemApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/operation/${queryArg.opId}/opItem/${queryArg.opItemId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["OpItems", "Balance"],
      }),
      deleteOperationOtherItem: build.mutation<
        DeleteOperationOtherItemApiResponse,
        DeleteOperationOtherItemApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/opOtherItems/${queryArg.opItemId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["OpItems", "Balance"],
      }),
      validateOperationItemCsvApi: build.mutation<
        ValidateOperationItemCsvApiApiResponse,
        ValidateOperationItemCsvApiApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/operation/${queryArg.opId}/opItems:validateCsv`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Operation", "OpItems", "Balance"],
      }),
      importOperationItemCsv: build.mutation<
        ImportOperationItemCsvApiResponse,
        ImportOperationItemCsvApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/operation/${queryArg.opId}/opItems:importCsv`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Operation", "OpItems", "Balance"],
      }),
      getOperationShugi: build.query<
        GetOperationShugiApiResponse,
        GetOperationShugiApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/opShugis`,
          params: { opId: queryArg.opId },
        }),
        providesTags: ["opShugis"],
      }),
      postOperationShugi: build.mutation<
        PostOperationShugiApiResponse,
        PostOperationShugiApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/opShugis`,
          method: "POST",
          body: queryArg.body,
          params: { opId: queryArg.opId },
        }),
        invalidatesTags: ["opShugis", "Balance", "Operation"],
      }),
      findOperationShugi: build.query<
        FindOperationShugiApiResponse,
        FindOperationShugiApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/opShugis/find`,
          params: {
            opId: queryArg.opId,
            name: queryArg.name,
            page: queryArg.page,
            type: queryArg["type"],
            itemRelatedOnly: queryArg.itemRelatedOnly,
          },
        }),
        providesTags: ["opShugis"],
      }),
      solveWarning: build.mutation<SolveWarningApiResponse, SolveWarningApiArg>(
        {
          query: (queryArg) => ({
            url: `/v1/appli/operation/${queryArg.opId}/opShugi:solveWarning`,
            method: "POST",
            body: queryArg.body,
          }),
          invalidatesTags: ["opShugis", "Balance"],
        }
      ),
      deleteOperationShugi: build.mutation<
        DeleteOperationShugiApiResponse,
        DeleteOperationShugiApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/operation/${queryArg.opId}/opShugi/${queryArg.opShugiId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["opShugis", "Balance", "Operation"],
      }),
      reorderOperationShugi: build.mutation<
        ReorderOperationShugiApiResponse,
        ReorderOperationShugiApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/operation/${queryArg.opId}/opShugi:reOrder`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["opShugis", "Balance", "Operation"],
      }),
      postOperationKasanOption: build.mutation<
        PostOperationKasanOptionApiResponse,
        PostOperationKasanOptionApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/appli/ops/${queryArg.opId}/kasanOption`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["opShugis", "Balance"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as api };
export type GetServiceStatusApiApiResponse =
  /** status 200 OK */ HeaderResource;
export type GetServiceStatusApiApiArg = void;
export type DepartmentListApiApiResponse = /** status 200  */ {
  id?: number;
  name?: string;
}[];
export type DepartmentListApiApiArg = void;
export type DoctorListApiApiResponse =
  /** status 200  */ ClinicDoctorResource[];
export type DoctorListApiApiArg = void;
export type OperationPrintApiApiResponse = unknown;
export type OperationPrintApiApiArg = {
  startDate: string;
  endDate?: string;
  keyword?: string;
  isAsc?: boolean;
};
export type FindItemsApiResponse = /** status 200 OK */ {
  limit?: number;
  pages?: number;
  items?: OpRegistrableItemResource[];
};
export type FindItemsApiArg = {
  /** 手術ID */
  name: string;
  /** 手術ID */
  offset: number;
  /** 手術ID */
  type: string;
};
export type OperationListApiApiResponse =
  /** status 200  */ OperationSearchResult[];
export type OperationListApiApiArg = {
  startDate: string;
  endDate?: string;
  keyword?: string;
  isAsc?: boolean;
};
export type PostOperationApiApiResponse = /** status 200  */ OperationResult;
export type PostOperationApiApiArg = {
  body: {
    operationDate: string;
    startTime?: string | null;
    departmentId: number;
    doctorId: number;
    responsibleDivision?: string;
    memo?: string | null;
  };
};
export type OperationCsvDownloadApiResponse = unknown;
export type OperationCsvDownloadApiArg = {
  startDate: string;
  endDate?: string;
  keyword?: string;
  isAsc?: boolean;
};
export type IsExternalIdExistsApiResponse = /** status 200  */ boolean;
export type IsExternalIdExistsApiArg = {
  body: {
    externalOpId: string;
  };
};
export type GetOperationDetailApiApiResponse =
  /** status 200  */ OperationResult;
export type GetOperationDetailApiApiArg = {
  opId: number;
};
export type PutOperationApiApiResponse = /** status 200  */ OperationResult;
export type PutOperationApiApiArg = {
  opId: number;
  body: {
    operation: {
      operationDate?: string;
      startTime?: string | null;
      departmentId?: number;
      doctorId?: number;
      responsibleDivision?: string;
      memo?: string | null;
      weight?: number;
    };
    mask?: (
      | "operationDate"
      | "startTime"
      | "departmentId"
      | "doctorId"
      | "responsibleDivision"
      | "memo"
      | "weight"
    )[];
  };
};
export type DeleteOperationApiApiResponse = /** status 200 Success */ null;
export type DeleteOperationApiApiArg = {
  opId: number;
};
export type GetBalanceApiResponse = /** status 200 OK */ GetBalanceResponse;
export type GetBalanceApiArg = {
  /** 手術ID */
  opId: number;
};
export type ValidateOperationCsvApiResponse = /** status 200 OK */ {
  errors: {
    line: number;
    error_level: string;
    message: string;
  }[];
};
export type ValidateOperationCsvApiArg = {
  body: {
    csv_file?: Blob;
  };
};
export type PostOperationImportApiResponse = /** status 200 OK */ {
  errors: {
    line: number;
    error_level: string;
    message: string;
  }[];
};
export type PostOperationImportApiArg = {
  body: {
    csv_file?: Blob;
  };
};
export type GetOperationItemApiResponse = /** status 200 OK */ OperationItems[];
export type GetOperationItemApiArg = {
  /** 手術ID */
  opId: number;
};
export type PostOperationItemApiResponse = /** status 200 OK */ null;
export type PostOperationItemApiArg = {
  body: {
    opId: number;
    itemId: number | null;
    yakuhinId: number | null;
    tokuteiId: number | null;
    quantity: number;
    lotLimit: {
      lot: string;
      limitDate: string;
    }[];
  };
};
export type PostOperationItemWithJancodeApiResponse = /** status 200 OK */ null;
export type PostOperationItemWithJancodeApiArg = {
  body: {
    opId: number;
    jancode?: string | null;
    quantity: number;
    lotLimit: {
      lot: string;
      limitDate: string;
    }[];
  };
};
export type PutOperationItemApiResponse = /** status 200 OK */ null;
export type PutOperationItemApiArg = {
  /** 物品IDを指定する */
  opItemId: number;
  body: {
    quantity: number;
    lotLimit: {
      lot: string;
      limitDate: string;
    }[];
  };
};
export type SetOperationItemCaluculationStatusApiResponse =
  /** status 200 OK */ null;
export type SetOperationItemCaluculationStatusApiArg = {
  /** 手術ID */
  operationId: number;
  /** 利用物品ID */
  opItemId: number;
  /** 利用物品ID */
  tokuteiId: number;
  body: {
    count: number;
  };
};
export type PutOperationOtherItemApiResponse = /** status 200 OK */ null;
export type PutOperationOtherItemApiArg = {
  /** 手術ＩＤ */
  opId: number;
  /** 使用物品ID */
  opOtherItemId: number;
  body: {
    quantity: number;
  };
};
export type DeleteOperationItemApiResponse = /** status 200 OK */ null;
export type DeleteOperationItemApiArg = {
  /** 手術ＩＤ */
  opId: number;
  /** 使用物品ID */
  opItemId: number;
};
export type DeleteOperationOtherItemApiResponse = /** status 200 OK */ null;
export type DeleteOperationOtherItemApiArg = {
  /** opItemId */
  opItemId: number;
};
export type ValidateOperationItemCsvApiApiResponse = /** status 200 OK */ {
  errors: {
    line: number;
    error_level: string;
    message: string;
  }[];
};
export type ValidateOperationItemCsvApiApiArg = {
  /** 手術ＩＤ */
  opId: number;
  body: {
    csv_file?: Blob;
  };
};
export type ImportOperationItemCsvApiResponse = /** status 200 OK */ {
  errors: {
    line: number;
    error_level: string;
    message: string;
  }[];
};
export type ImportOperationItemCsvApiArg = {
  /** 手術ＩＤ */
  opId: number;
  body: {
    csv_file?: Blob;
  };
};
export type GetOperationShugiApiResponse =
  /** status 200 OK */ OperationShugisOperation;
export type GetOperationShugiApiArg = {
  /** 手術ID */
  opId: number;
};
export type PostOperationShugiApiResponse =
  /** status 200 OK */ OperationShugiResource[];
export type PostOperationShugiApiArg = {
  /** 手術ID */
  opId: number;
  body: {
    opId?: number;
    opShugis?: OperationShugiResource[];
  };
};
export type FindOperationShugiApiResponse =
  /** status 200 OK */ FindOperationShugisResponse;
export type FindOperationShugiApiArg = {
  /** 手術ID */
  opId: number;
  /** 手術名又はコードで絞り込む */
  name?: string;
  /** ページ番号 */
  page?: number;
  /** 手技の種類 */
  type: ShugiType;
  /** 関連利用物品フラグ<BR>
               '1'  - 利用物品に関連する項目を取得<BR>
               '0' - 全ての項目を取得 */
  itemRelatedOnly: "1" | "0";
};
export type SolveWarningApiResponse =
  /** status 200 OK */ OperationShugiResource[];
export type SolveWarningApiArg = {
  /** 手術ID */
  opId: number;
  body: {
    opShugis?: {
      opShugiId?: number;
      isCalculate?: boolean;
    }[];
  };
};
export type DeleteOperationShugiApiResponse = unknown;
export type DeleteOperationShugiApiArg = {
  /** 手術ID */
  opId: number;
  /** 手術手技ID */
  opShugiId: number;
};
export type ReorderOperationShugiApiResponse = unknown;
export type ReorderOperationShugiApiArg = {
  /** 手術ID */
  opId: number;
  body: {
    opShugiId: number;
    order: number;
  }[];
};
export type PostOperationKasanOptionApiResponse = /** status 200 OK */ null;
export type PostOperationKasanOptionApiArg = {
  /** 手術ID */
  opId: number;
  body: {
    type?: string;
    kasanOptionId?: number | null;
  };
};
export type HeaderResource = {
  readyToService: boolean;
  isMaintenance: boolean;
};
export type ClinicDoctorResource = {
  id: number;
  name: string;
  department_id: number;
};
export type OpRegistrableItemResource = {
  itemId: number;
  yakuhinId: number;
  tokuteiId: number;
  JAN: string;
  name?: string;
  yomi?: string;
  kikaku: string;
  makerName: string;
  itemCode: string;
  medicalClass: number;
  medieCode: string;
  cost: number;
  quantity: number;
  isNarcotic: boolean;
  isStimulant: boolean;
  isBloodDerivative: boolean;
};
export type OperationSearchResult = {
  id: number;
  operationDate: string;
  startTime?: string;
  externalOpId: string;
  responsibleDivision: string;
  weight?: number;
  departmentName: string;
  doctorName: string;
  totalCost: number;
  totalIncome: number;
  shugi: string;
  memo?: string;
  isEmpty: boolean;
};
export type OperationResult = {
  id: number;
  operationDate: string;
  startTime?: string;
  externalOpId: string;
  responsibleDivision: string;
  weight?: number;
  departmentId: number;
  doctorId: number;
  memo: string;
};
export type BadRequestError = {
  message: string;
};
export type BalanceOperationKasan = {
  id?: number;
  name?: string;
  kubun?: string;
  amount?: number;
  unit?: string;
  sign?: string;
  point: number;
  pointUnit?: string;
  isUsed?: boolean;
  isCalculate?: boolean;
};
export type BalanceOperationShugi = {
  id: number;
  order?: number;
  kubun?: string;
  shugiId?: number;
  name?: string;
  kizami?: 1 | 0;
  amount?: number;
  unit?: string;
  point: number;
  isCalculate?: boolean;
  isPrimary?: boolean;
  relationShitType?: "UNMARKED" | "UNRELATED" | "PRIMARY" | "SLAVE" | "IGNORE";
  kasans: BalanceOperationKasan[];
};
export type BalanceShugiSection = {
  kind: "shugi";
  name: string;
  subTotal: number;
  operationShugis: BalanceOperationShugi[];
  kasans: BalanceOperationKasan[];
};
export type OperationItemLot = {
  lot: string;
  limitDate: string;
};
export type OperationItemsTokutei = {
  id: number;
  code?: string;
  name: string;
  cost: number;
};
export type OperationItemsOperationTokutei = {
  tokuteiId: number;
  count: number;
};
export type OperationItems = {
  opId: number;
  opItemId: number;
  opOtherItemId: number;
  itemId: number;
  yakuhinId: number;
  tokuteiId: number;
  itemName: string;
  itemYomi: string;
  makerName: string;
  medicalClass: number;
  quantity: number;
  lotLimit: OperationItemLot[];
  cost: number;
  unitAmount: number;
  amount: number;
  itemCode: string;
  clinicCode: string;
  isNarcotic: boolean;
  isStimulant: boolean;
  isBloodDerivative: boolean;
  seikyuNaiyou?: string;
  yakuhin_receipt_code?: string;
  yakuhin_receipt_name?: string;
  isSelectedTokuteiItem?: boolean;
  tokuteis: OperationItemsTokutei[];
  operationTokutei: OperationItemsOperationTokutei[];
};
export type BalanceItemSection = {
  kind: "item";
  name: string;
  subTotal: number;
  items: OperationItems[];
};
export type BalanceIncome = {
  totalIncome: number;
  sections: (BalanceShugiSection | BalanceItemSection)[];
};
export type BalanceOutGoItemSection = {
  kind: "item";
  name: string;
  subTotal: number;
  items: OperationItems[];
};
export type BalanceOutgo = {
  totalCost: number;
  sections: (BalanceShugiSection | BalanceOutGoItemSection)[];
};
export type BalanceOperation = {
  operationDate: string;
  departmentName: string;
  doctorName: string;
  externalOpId?: string;
  income: BalanceIncome;
  outgo: BalanceOutgo;
};
export type WarningResource = {
  kind?: string;
  id1?: number;
  id2?: number;
  message?: string;
};
export type GetBalanceResponse = {
  operation: BalanceOperation;
  warnings: WarningResource[];
};
export type Shugi = {
  id: number;
  name: string;
  kubun: string;
  code?: string;
  kizami?: 0 | 1;
  kizami_under?: number;
  kizami_limit?: number;
  kizami_unit: number;
  unit?: string;
};
export type OperationKasan = {
  id: number;
  kubun: string;
  code: string;
  name: string;
  receptCode?: string;
  isUsed?: boolean;
  isCalculate?: boolean;
  revenue?: number;
  relatedOperationItems?: {
    name?: string;
    id?: number;
  }[];
};
export type OperationShugiResource = {
  id: number;
  type: "shugi" | "masui" | "yuketsu" | "others";
  order: number;
  amount: number;
  isCalculate: boolean;
  isPrimary: boolean;
  relatedItemCount: number;
  relatedItemRevenue?: number;
  shugiRevenue?: number;
  shugi: Shugi;
  kasans: OperationKasan[];
  relatedOperationItems?: {
    name?: string;
    id?: number;
  }[];
};
export type KasanOption = {
  id: number;
  type: string;
  name: string;
  is_used?: boolean;
};
export type OperationShugisOperation = {
  operationShugis: OperationShugiResource[];
  nenreiKasanOptions: KasanOption[];
  jikangaiKasanOptions: KasanOption[];
};
export type FindOperationShugisResponse = {
  currentPage: number;
  perPage: number;
  lastPage: number;
  total: number;
  list: OperationShugiResource[];
};
export type ShugiType =
  | "shugi"
  | "shochi"
  | "kensa"
  | "masui"
  | "yuketsu"
  | "yuketsu-kensa";
export const {
  useGetServiceStatusApiQuery,
  useDepartmentListApiQuery,
  useDoctorListApiQuery,
  useOperationPrintApiMutation,
  useFindItemsQuery,
  useOperationListApiQuery,
  usePostOperationApiMutation,
  useOperationCsvDownloadMutation,
  useIsExternalIdExistsMutation,
  useGetOperationDetailApiQuery,
  usePutOperationApiMutation,
  useDeleteOperationApiMutation,
  useGetBalanceQuery,
  useValidateOperationCsvMutation,
  usePostOperationImportMutation,
  useGetOperationItemQuery,
  usePostOperationItemMutation,
  usePostOperationItemWithJancodeMutation,
  usePutOperationItemMutation,
  useSetOperationItemCaluculationStatusMutation,
  usePutOperationOtherItemMutation,
  useDeleteOperationItemMutation,
  useDeleteOperationOtherItemMutation,
  useValidateOperationItemCsvApiMutation,
  useImportOperationItemCsvMutation,
  useGetOperationShugiQuery,
  usePostOperationShugiMutation,
  useFindOperationShugiQuery,
  useSolveWarningMutation,
  useDeleteOperationShugiMutation,
  useReorderOperationShugiMutation,
  usePostOperationKasanOptionMutation,
} = injectedRtkApi;
