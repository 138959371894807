import {Route, BrowserRouter, Outlet } from "react-router-dom";
import React, {FC, useEffect, useState,} from "react";
import MenuPage from "../pages/Menu";
import {Navigate,Routes } from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import Auth0LoginNavigation from "../pages/Auth0LoginNavigation";
import EditBasicInfoPage from "../pages/BasicInfoEditPage";
import Shugi from "../pages/Shugi";
import OpItem from "../pages/OpItem";
import Balance from "../pages/Balance";
import {SelectOpItemPage} from "../pages/SelectOpItemPage";
import {SuggestedShuigiListPage} from "../pages/SuggestedShugiList";
import {EditorNav} from "../organisms/EditorNav";
import {Operations} from "../layout/Operations";
import InsuranceInformation from "../pages/InsuranceInformation";
import {LoggedInPage} from "../pages/LoggedInPage";
import {NewBasicInfoContainer} from "../organisms/NewBasicInfoContainer";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    styled,
    TextField, Typography
} from "@mui/material";
import {ToastBlock} from "../atoms/ToastBlock";
import {useDispatch} from "react-redux";
import {useDeselectOperation} from "../../app/slices/operationSlice";
import {useGetServiceStatusApiQuery} from "../../store/api";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import dayjs from "dayjs";

type AppState = "Home" | "Editor";

const EditorContent: FC<{setAppState: (newState: AppState) => void }> = (props) => {
    console.log("EditorContent")

    useEffect(() => {
        props.setAppState("Editor");
    }, [props.setAppState]);

    const element = (
        <div className="m-layout ">
            <div className="m-splitColumns">
                <div className={"m-splitColumns__editor "}>
                    <EditorNav/>
                    <Outlet />
                </div>
            </div>
        </div>
    );

    return element;
}

const HomeContext: FC<{setAppState: (newState: AppState) => void }> = (props) => {
    console.log("HomeContext")
    const deselectOperation = useDeselectOperation();

    useEffect(() => {
        props.setAppState("Home");

        deselectOperation();

    }, [props.setAppState]);

    return (<></>);
}

const StyledBox = styled(Box)`
  ${({ theme }) => `
    transition: ${theme.transitions.create(['width'], {duration: theme.transitions.duration.standard })};
    width: 100%;
    overflow-y: scroll;
    position: relative;
    display: block;
    height: 100vh;
    &.side {
        display: inline-block;
        width: 33%;
        min-width: 480px;
    }
`}`;


const AppContext: FC<{appState: AppState}> = (props) => {
    console.log("AppContext")

    const {isAuthenticated, user} = useAuth0();
    const { data: status } = useGetServiceStatusApiQuery({} as any, {
        skip: !isAuthenticated
    });

    if (!isAuthenticated) {
        return (<Auth0LoginNavigation />)
    }

    // ADMIN組織にログインしている場合はADMIN画面へ飛ばす
    if ( user?.org_id == (process.env.REACT_APP_ADMIN_ORG_ID ?? "")) {
        window.location.href = process.env.REACT_APP_ADMIN_URL ?? "";
        return <></>;
    }

    return (
        <>
            <ToastBlock />
            <Stack direction={"row"}>
                <StyledBox className={props.appState == "Home" ? "" : "side" }>
                    <Operations
                        className={ props.appState == "Home" ? "list" : "m-splitColumns" }
                        isSmall={ props.appState == "Home" ? false : true }
                    />
                </StyledBox>

                <Outlet />
            </Stack>

            <Dialog
                open={status?.readyToService == false ?? false}
                onClose={ () => {} }
                aria-labelledby="modal-modal-title"
                fullWidth={true}
                aria-describedby="modal-modal-description">
                <DialogTitle id="alert-dialog-title">
                    メンテナンス情報
                </DialogTitle>

                <DialogContent >
                    <Typography marginTop={3} marginBottom={3}>現在メンテナンス中です</Typography>
                </DialogContent>
            </Dialog>

            <Dialog
                open={status?.isMaintenance ?? false}
                onClose={ () => {} }
                aria-labelledby="modal-modal-title"
                fullWidth={true}
                aria-describedby="modal-modal-description">
                <DialogTitle id="alert-dialog-title">
                    メンテナンス情報
                </DialogTitle>

                <DialogContent >
                    <Typography marginTop={3} marginBottom={3}>現在メンテナンス中です</Typography>
                </DialogContent>
            </Dialog>

        </>
)
}

const RouterSwitches = () => {
    console.log("RouterSwitches")

    const [appState, setAppState] = useState<AppState>("Home");

    return (
        <>
            <Routes>
                {/*ログイン*/}
                <Route path={`/login`} element={<Auth0LoginNavigation />} />
                <Route path={`/tenant/:orgId/login`} element={<Auth0LoginNavigation />} />

                {/*ログイン完了時のリダイレクト先*/}
                <Route path={`/logged_in`} element={<LoggedInPage />}/>

                {/* Authガードと手術一覧 */}
                <Route path={"/*"} element={<AppContext appState={appState} />}>
                    {/* 手術一覧の全画面化, ログイン後ファーストビュー */}
                    <Route path={`home`}  element={<HomeContext setAppState={setAppState} />}/>

                    {/* 編集ページ, 手術一覧のサイドパネル化 */}
                    <Route path={`operation/*`} element={<EditorContent setAppState={setAppState}  />}>
                        {/* 手術新規登録 */}
                        <Route path={ `new`}  element={<NewBasicInfoContainer />} />
                        {/* 手術基本情報編集 */}
                        <Route path={ `:operationId/detail`                               } element={<EditBasicInfoPage />} />

                        {/* 手術一覧 */}
                        <Route path={ `:operationId/shugis/:shugiTab/list/:shugiListType` } element={<SuggestedShuigiListPage />}/>

                        {/* 手術*/}
                        <Route path={ `:operationId/shugis/:shugiTab`                     } element={<Shugi />} />
                        <Route path={ `:operationId/shugis`                               } element={<Shugi />} />
                        <Route path={ `:operationId/op_item/select`                       } element={<SelectOpItemPage />} />
                        <Route path={ `:operationId/op_item`                              } element={<OpItem />} />
                        <Route path={ `:operationId/mci_info/:mciTab`                     } element={<InsuranceInformation /> }/>
                        <Route path={ `:operationId/mci_info`                             } element={<InsuranceInformation /> }/>
                        <Route path={ `:operationId/balance/:balanceTab`                  } element={<Balance /> }/>
                        <Route path={ `:operationId/balance`                              } element={<Navigate replace to={`income`}/> }/>
                        <Route path={"*"} element={<Navigate replace to={"/home"} />} />
                    </Route>
                    <Route path={"*"} element={<Navigate replace to={"/home"} />} />
                </Route>
            </Routes>

        </>
    );
}

export const RecepoRouter = () => {
    return (
        <BrowserRouter>
            <RouterSwitches />
            <MenuPage/>
        </BrowserRouter>
    );
}