import React, {FC} from "react";
import Switch from "@mui/material/Switch";
import {SwitchProps} from "@mui/material/Switch/Switch";
import {Fab, Typography} from "@mui/material";

export const CircleButton: FC<{
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    name: string | React.ReactNode;
}> = (props,context) => {
    return (
        <>
            <Fab
                onClick={ props.onClick }
                variant={"circular"}
                color={"primary"}
                sx={{
                }}>
                {props.children}
            </Fab>
            <Typography fontSize={"small"} color={"primary"} width={55} textAlign={"center"} marginTop={"4px"} lineHeight={1.1}>
                {props.name}
            </Typography>
        </>
)
}

export const RecepoSwitch: FC<SwitchProps> = (props) => {
    return (<Switch
        { ...props }

        sx={{
            ".MuiSwitch-track": {
                "backgroundColor": "#c2c2c2",
            },
            ".Mui-checked+.MuiSwitch-track": {
                "backgroundColor": "#99f8ba",
            },
            ".MuiSwitch-switchBase": {
                "color": "#f5f5f5",
            },
            ".Mui-checked": {
                "color": "#00ee52",
            },
        }}
    />)


}

const styles = {
    circleButton: {
        color: "#034c8c",
        fontSize: "13px",
        fontWeight: "700",
        marginLeft: "auto",
        textAlign: "center",
        width: "58px"
    } as React.CSSProperties
}