import React, {FC, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import BasicInfoForm from "./BasicInfoForm";
import {AppDispatch} from "../../app/store";
import {
    resetOperation, selectEditedOperation,
    selectOperation,
} from "../../app/slices/operationSlice";
import {
    OperationResult,
    useGetOperationDetailApiQuery, usePutOperationApiMutation
} from "../../store/api";
import dayjs from "dayjs";
import {Alert, Backdrop, CircularProgress, Snackbar} from "@mui/material";
import {actions, popToastMessages} from "../../app/slices/ui/toastSlice";

export const EditBasicInfoContainer: FC<{operationId: number}> = ({operationId}) => {
    const dispatch: AppDispatch = useDispatch();
    const editedOperation = useSelector(selectEditedOperation);
    const toastMessages = useSelector(popToastMessages("basicInfo"));
    const [putOperationAPI ] = usePutOperationApiMutation();

    const { data,isFetching } = useGetOperationDetailApiQuery({
        opId: operationId
    });

    useEffect( () => {
        dispatch(resetOperation())
    },[operationId, dispatch])

    useEffect( () => {
        if (data != null) {
            dispatch(selectOperation(data))
        }
    },[data])

    if (editedOperation == null || editedOperation.id !== operationId) {
        return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>)
    }

    const handleSave = async () => {

        const payload: OperationResult = Object.assign({}, editedOperation, {
            "doctorId": editedOperation.doctorId == 0 ? null :  editedOperation.doctorId,
            "departmentId": editedOperation.departmentId == 0 ? null :  editedOperation.departmentId,
            "operationDate": dayjs(editedOperation.operationDate).format('YYYY-MM-DD')
        });

        const result = await putOperationAPI({
            opId: operationId,
            body: {
                operation: payload
            }
        })

        if ("error" in result) {

            dispatch(actions.putToast({ kind: "basicInfo", text: "エラーが発生しました", severity: "error" }))

        } else if ("data" in result) {

            dispatch(actions.putToast({ kind: "basicInfo", text: "基本情報を更新しました。", severity: "success" }))
        }
    }

    return (
        <React.Fragment>
            { toastMessages.length > 0 && toastMessages.map( (message,o) => (
                <Snackbar
                    open={true}
                    anchorOrigin={{ vertical: 'top', horizontal: "center" }}
                    key={o}
                    sx={{ marginTop: 10 }} >
                    <Alert severity={message.severity} sx={{ width: '100%' }}>
                        {message.toastText}
                    </Alert>
                </Snackbar>)
            )}
            <BasicInfoForm
                onSave={handleSave}
                editedOperation={editedOperation} />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFetching}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>)
}