import React, {FC, useRef, useState} from "react";
import {
    FindOperationShugiApiArg,
    FindOperationShugisResponse,
    OperationShugiResource,
    ShugiType,
    useFindOperationShugiQuery,
    useGetOperationShugiQuery,
    usePostOperationShugiMutation
} from "../../store/api";
import {useOperationIdParam, useShugiListTypeParam, useShugiTabParam} from "../../hooks/useOperationIdParameter";
import {
    Backdrop, Box, Button, Card, CardContent, Chip, CircularProgress, Divider, Grid, Pagination, Stack, Typography,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {RecepoSwitch} from "../atoms/Buttons";
import {Add, HorizontalRule} from "@mui/icons-material";
import {SearchTextInput} from "../atoms/TextFields";
import {MListItem} from "../atoms/MListItemColumn";

const OperationShugiListItem: FC<{ data: OperationShugiResource,
    handleEndRegister: (shugi :OperationShugiResource) => void,}> = ({ data, handleEndRegister }) => {

    let [isExpand, setExpand] = useState(false);

    return (

        <MListItem onClick={() => handleEndRegister( data ) } >

        <CardContent sx={{}}>
            <Grid container>

                <Grid item xs={2}>
                    <Typography sx={{ fontSize: 13 }} color="#034c8c" gutterBottom>
                        コード
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography sx={{ fontSize: 13 }} color="#034c8c" gutterBottom>
                        診療行為
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={{ fontSize: 13}} color="#034c8c" gutterBottom>
                        手術手技料
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={{ fontSize: 13}} color="#034c8c" gutterBottom>
                        算定見込み保険金額
                    </Typography>
                </Grid>


                <Grid item xs={2}>
                    <Typography sx={{ fontSize: 18, fontWeight: "bold"  }} color="#034c8c" gutterBottom>
                        {data.shugi.kubun}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="#034c8c" gutterBottom>
                        {data.shugi.name}
                    </Typography>
                </Grid>

                <Grid item xs={2}>
                    <Typography sx={{ fontSize: 18, fontWeight: "bold"  }} color="#034c8c" gutterBottom>
                        { ((data.shugiRevenue)  ?? 0).toLocaleString() }
                    </Typography>
                </Grid>

                <Grid item xs={2}>
                    <Typography sx={{ fontSize: 18, fontWeight: "bold"  }} color="#034c8c" gutterBottom>
                        { ((data.relatedItemRevenue)  ?? 0 * 10).toLocaleString() }
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography sx={{ fontSize: 18, fontWeight: "bold"  }} color="#034c8c" gutterBottom>
                        <Button variant={"outlined"}
                                onClick={ (event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    setExpand(!isExpand)
                                }}>{! isExpand ? (<Add />) : (<HorizontalRule/>) }
                        </Button>
                    </Typography>
                </Grid>
            </Grid>

            <Box display={ isExpand ? "block" : "none"}>

                <Divider variant="middle" />

                <Typography marginTop={0.5} sx={{ fontSize: 13, fontWeight: "bold"  }} color="#034c8c" gutterBottom>
                    関連利用物品
                </Typography>
                <Box marginTop={1} >

                </Box>
                <Box marginTop={1} >
                    { data?.relatedOperationItems?.map( (x, i) =>
                        (<Chip key={i} sx={{margin: "1px"}} size={"small"} label={x.name}/>)
                    )}
                </Box>
            </Box>
        </CardContent>
    </MListItem>);
}

/***
 * 診療行為選択
 *
 * @constructor
 */
const OperationShugiList: FC<{ data: FindOperationShugisResponse,
    searchCondition: { name?: string, page?: number, checked?: boolean },
    refetch: ()=>void,
    onCancel: ()=>void,
    onChangeSearchCondition: (key: string, value: any) => void,
    handleEndRegister: (shugi :OperationShugiResource) => void,
    isFullList: boolean;
    type: string }> = (
    {searchCondition, onChangeSearchCondition, refetch,data, handleEndRegister, type, onCancel, isFullList}) => {

    const scrollBox = useRef<HTMLDivElement>(null);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            refetch()
        }
    }

    return (
        <Box
        ref={scrollBox}
            sx={{
            height: "calc( 100% - 120px )",
            overflowY: "scroll"
        }}>
            <Box sx={{
                margin: "50px auto 50px",
                maxWidth: "1180px",
                display: "flex"
            }}>
                <Box>
                    <Button type="button" onClick={onCancel} fullWidth>
                        <img src="./images/icon_arrow_left.svg" alt="" decoding="async" />
                    </Button>
                </Box>

                <Box width={"100%"}>

                    <Stack direction={"row"} alignItems={"center"}>
                        <Box width={400}>
                            <SearchTextInput
                                fullWidth
                                onSearch={ (text) => onChangeSearchCondition("name", text) }
                            />
                        </Box>
                    </Stack>

                    { !isFullList  && (
                        <Stack direction={"row"} alignItems={"center"}>
                            <Typography fontWeight={600} color={"primary"}>利用物品に関係する診療行為のみを表示</Typography>
                            <RecepoSwitch
                                checked={searchCondition.checked}
                                onChange={(ev) => onChangeSearchCondition("checked", !searchCondition.checked) } />
                        </Stack>
                    )}

                    <Pagination count={ data?.lastPage }
                                variant="outlined"
                                shape="rounded"
                                page={ data?.currentPage }
                                onChange={ (_, page) => onChangeSearchCondition("page", page) }
                                sx={{
                                    mt: 3,
                                    mb: 3,
                                    "button": {
                                        borderColor: "rgb(3, 76, 140)",
                                        borderWidth: "2px"
                                    }
                                }}/>

                    <Box gap={5}>
                        {data?.list.map( (e) => {
                            return (<OperationShugiListItem
                                key={e.shugi.id}
                                data={e}
                                handleEndRegister={handleEndRegister} />)
                        })}
                    </Box>

                    <Pagination count={ data?.lastPage }
                                variant="outlined"
                                shape="rounded"
                                page={ data?.currentPage }
                                onChange={ (_, page) => {
                                    if (scrollBox.current)
                                        scrollBox.current.scrollTo(0,0);

                                    onChangeSearchCondition("page", page)
                                } }
                                sx={{
                                    mt: 3,
                                    "button": {
                                        borderColor: "rgb(3, 76, 140)",
                                        borderWidth: "2px"
                                    }
                                }}/>

                </Box>

            </Box>
        </Box>)
}

type OperationShugiListCondition = {
    checked: boolean,
    page: number,
    name: string,
};

const ShujutsuList: FC<{
    handleEndRegister: (shugi :OperationShugiResource) => void,
    onCancel:() => void
    type: ShugiType,
    isFullList: boolean
}> = ({ handleEndRegister, type,onCancel,isFullList }) => {

    let operationId  = useOperationIdParam();

    const [condition, setCondition] = useState<OperationShugiListCondition>({
        checked: true,
        page: 1,
        name: "",
    });

    const buildFindOperationShugiQueryArg: (params: OperationShugiListCondition) => FindOperationShugiApiArg = (params: OperationShugiListCondition) => ({
        opId: operationId,
        type,
        name: condition.name,
        page: condition.page,
        itemRelatedOnly: isFullList ? "0" : condition.checked ? "1" : "0"})

    const { data, refetch, isFetching } = useFindOperationShugiQuery(buildFindOperationShugiQueryArg(condition));

    const handleSetCondition = (key:string, value: any) => {

        const params: OperationShugiListCondition = { ...condition, [key]: value };

        if (key != "page") {
            // ページング以外の操作をした際は１ページ目に戻します。
            setCondition({...params, page: 1});
        } else {
            setCondition(params);
        }

    }

    return (
        <>
            { data && (
                <OperationShugiList
                    data={ data }
                    refetch={refetch}
                    searchCondition={condition}
                    onChangeSearchCondition={handleSetCondition}
                    onCancel={onCancel}
                    handleEndRegister={handleEndRegister}
                    isFullList={isFullList}
                    type={type} />)
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFetching}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </>)
}

export const SuggestedShuigiListPage: React.FC = () => {
    let operationId = useOperationIdParam();
    let tab = useShugiTabParam();
    let shugiListType = useShugiListTypeParam();

    let navigate = useNavigate();

    const { data: opShugisOpration, isFetching } =
        useGetOperationShugiQuery({opId: operationId }, { refetchOnMountOrArgChange: true })

    const [ postOperationShugi, option ] = usePostOperationShugiMutation()

    const [isFullList, setIsFullList] = useState<boolean>(false);

    const onCancel = () => {
        navigate(`/operation/${operationId}/shugis/${tab}`, {replace: true})
    }

    if (shugiListType == null) {
        onCancel();
        return (<></>);
    }

    const handleEndRegister = (selected: OperationShugiResource) => {
        if (opShugisOpration?.operationShugis != null) {
            postOperationShugi({
                opId: operationId,
                body: {
                    opId: operationId,
                    opShugis: [...opShugisOpration?.operationShugis, selected]
                }
            })
        }

        onCancel();
    }

    return (
            <React.Fragment>
                <ShujutsuList handleEndRegister={handleEndRegister} onCancel={ onCancel }
                              type={shugiListType} isFullList={isFullList}/>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isFetching || option.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </React.Fragment>
    )
}