import {FC} from "react";
import {OperationItems} from "../../store/api";

/**
 * 医療機器クラスのラベル
 *
 * @param medicalClass
 * @constructor
 */
export const MedicalClassBadge: FC<{ item: OperationItems }> = ({item}) => {
    let className = "";
    let color     = "";
    let isShow      = true;

    if ( item.isNarcotic) {
        className = "麻薬";
        color = "tag_color_3";
    } else if (item.isStimulant) {
        className = "覚醒剤";
        color = "tag_color_3";
    } else if (item.isBloodDerivative) {
        className = "血液製剤";
        color = "tag_color_3";
    } else if (item.yakuhinId != null) {
        className = "医薬品";
        color = "tag_color_4";
    } else {
        switch (item.medicalClass) {
            case 1: className = "一般医療機器"; color = "tag_color_2"; break;
            case 2: className = "管理医療機器"; color = "tag_color_2"; break;
            case 3: className = "高度管理医療機器"; color = "tag_color_1"; break;
            case 4: className = "高度管理医療機器"; color = "tag_color_1"; break;
            default: isShow = false;
        }
    }

    if (isShow) {
        return (<div className={ `tag ${color}` }>{className}</div>)
    } {
        return (<></>)
    }
}
