import React, {FC, useEffect, useRef, useState} from "react";
import {MedicalClassBadge} from "../atoms/MedicalClassLabel";
import {JanCodeInput} from "../organisms/JanCodeInput";
import {
  OperationItems,
  useDeleteOperationItemMutation,
  useDeleteOperationOtherItemMutation,
  useGetOperationItemQuery,
  usePutOperationItemMutation, usePutOperationOtherItemMutation
} from "../../store/api";
import {useOperationIdParam} from "../../hooks/useOperationIdParameter";
import {useNavigate} from "react-router-dom";
import {
    Backdrop,
    Button,
    CircularProgress,
    Fab,
    Grid, Popover, Stack,
    TextField, Tooltip, Typography
} from "@mui/material";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import { Box } from '@mui/system';
import { RecepoSwitch} from "../atoms/Buttons";
import { useDispatch} from "react-redux";
import { modalActions } from "../../app/slices/modalSlice";
import {OpItemCsvImportModal} from "../modals/OpItemCsvImportModal";

// const QuantityInput: FC<{ opItem: OperationItems }> = ({ opItem }) => {
//
//   const [ updateOpItemMutation ] = usePutOperationItemMutation();
//   const [ updateOpOtherItemMutation ] = usePutOperationOtherItemMutation();
//
//   let operationId = useOperationIdParam();
//
//   const { data } = useGetOperationItemQuery({
//     opId: operationId
//   });
//
//   const handleUpdateOtherCount = (opItemId: number) => (quantity: number) => {
//     updateOpOtherItemMutation({
//       opId: operationId,
//       opOtherItemId: opItemId,
//       body: {
//         quantity: quantity,
//       }
//     });
//   }
//
//   const handleUpDateCount = (opItemId: number) => (quantity: number) => {
//       updateOpItemMutation({
//         opItemId: opItemId,
//         body: {
//           quantity: quantity,
//           lotLimit: opItem.lotLimit
//         }
//       });
//   }
//
//   const {
//     control,
//     getValues,
//     setValue,
//   } = useForm({
//     mode: 'all',
//     reValidateMode:"onBlur",
//     defaultValues: {
//       amount: "" + opItem.quantity ,
//     },
//   });
//

//
//   const handleBlur = () => {
//     // if (opItem.opItemId != null) {
//     //   handleUpDateCount( opItem.opItemId )( Number.parseInt( getValues().amount ));
//     // } else {
//     //   handleUpdateOtherCount( opItem.opOtherItemId )(  Number.parseInt( getValues().amount ))
//     // }
//   }
// }

const OperationItemLine : FC<{ opItem: OperationItems, onUpdated: () => void }>= ({opItem, onUpdated: doScrollTop }) => {
    let operationId = useOperationIdParam();

    const {data, isFetching} = useGetOperationItemQuery({
        opId: operationId
    });

    const [updateOpItemMutation, {isLoading: putLoading}] = usePutOperationItemMutation();
    const [deleteOpItemMutation, {isLoading: deleteLoading}] = useDeleteOperationItemMutation();
    const [deleteOpOtherItemMutation, {isLoading: deleteOtherLoading}] = useDeleteOperationOtherItemMutation();

    const makeDefaultValue = () => {

        return {
            lotLimit: opItem.lotLimit.filter( (e, i) => i < opItem.quantity).map( (e) => {
                return {
                    lot: e.lot ?? "",
                    limitDate: e.limitDate ?? ""
                }
            }).concat(Array.from(new Array(Math.max(0, opItem.quantity - opItem.lotLimit.length))).map( () => {return { lot: "", limitDate: "" }})),
            quantity: opItem.quantity,
        };
    }

    const {
        control,
        watch,
        reset,
        handleSubmit,
    } = useForm({
        mode: 'all',
        reValidateMode:"onBlur",
        defaultValues: makeDefaultValue(),
    });

    watch(value => {
        console.log(opItem.quantity)
        console.log(opItem.lotLimit.length)
        console.log(value)
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "lotLimit"
    });

    useEffect(() => {
        reset(makeDefaultValue())
    }, [opItem]);

    const onSubmit = handleSubmit((values) => {
        updateOpItemMutation({
            opItemId: opItem.opItemId,
            body: values
        }).then(doScrollTop)
    })

    const handleOpItemDelete = (opItem: OperationItems) => {
        if (opItem.opItemId != null) {
            deleteOpItemMutation({
                opId: opItem.opId,
                opItemId: opItem.opItemId })
                .then(doScrollTop)
        } else {
            deleteOpOtherItemMutation({ opItemId: opItem.opOtherItemId })
                .then(doScrollTop)
        }
    }

    let fieldRef = useRef<HTMLDivElement>(null);

    return (
        <tr key={opItem.opItemId != null ? "op_" + opItem.opItemId : "other_" + opItem.opOtherItemId}>

            <th>
                <MedicalClassBadge item={opItem}/>
                <div className="name">{opItem.itemName}</div>
                <div className="yomi">[{opItem.itemYomi}]</div>
            </th>
            <td>
                <div className="makername">{opItem.makerName}</div>
                <div className="makercode">{opItem.itemCode}</div>
            </td>
            <td>
                <form onSubmit={(event) => {
                    event.preventDefault();
                    onSubmit();
                }}>
                    {fields.map((item, index) => (
                        <Box key={item.id} marginBottom={1}>
                            <Controller
                                control={control}
                                name={`lotLimit.${index}.lot`}
                                rules={{maxLength: 16}}
                                render={({field, fieldState}) => (
                                    <>
                                        <TextField
                                            {...field}
                                            error={!!fieldState.error}
                                            variant={"filled"}
                                            label={"ロット番号" + (field.value === "" ? " 未入力" : "")}
                                            onBlur={(event) => {
                                                event.target.form?.requestSubmit();
                                            }}
                                        />
                                        {fieldState.error != null && (
                                            <Typography fontSize={"small"} sx={{color: "#BF2427"}}>※16文字以内で入力してください</Typography>
                                        )}
                                    </>
                                )}
                            />
                            <Controller
                                control={control}
                                rules={{maxLength: 16}}
                                render={({field, fieldState}) => (
                                    <>
                                        <TextField
                                            {...field}
                                            error={!!fieldState.error}
                                            variant={"filled"}
                                            label={"使用期限" + (field.value === "" ? " 未入力" : "")}
                                            onBlur={(event) => {
                                                event.target.form?.requestSubmit();
                                            }}
                                        />
                                        {fieldState.error != null && (
                                            <Typography fontSize={"small"} sx={{color: "#BF2427"}}>※16文字以内で入力してください</Typography>
                                        )}
                                    </>)}
                                name={`lotLimit.${index}.limitDate`}
                            />
                        </Box>
                    ))}
                </form>
            </td>

            <td>
                <form onSubmit={(event) => {
                    event.preventDefault();
                    onSubmit();
                }}>
                    <div className="num" ref={fieldRef}>
                        <Controller control={control}
                                    name={"quantity"}
                                    rules={{min: 1}}
                                    render={({field, fieldState}) => (
                                        <>
                                                <TextField
                                                    {...field}
                                                    hiddenLabel
                                                    type="number"
                                                    error={fieldState.error != null}
                                                    color={ fieldState.error != null?  "error" : "primary" }
                                                    ref={ (r) => {  field.ref(r) }}
                                                    value={field.value}
                                                    aria-valuemin={1}
                                                    sx={{
                                                        '& input[type=number]': {
                                                            'mozAppearance': 'textfield'
                                                        },
                                                        '& input[type=number]::-webkit-outer-spin-button': {
                                                            opacity: 1
                                                        },
                                                        '& input[type=number]::-webkit-inner-spin-button': {
                                                            opacity: 1
                                                        }
                                                    }}

                                                    onBlur={(event) => {
                                                        event.target.form?.requestSubmit();
                                                    }}
                                                />
                                                {fieldState.error != null && (
                                                    <Typography fontSize={"small"} sx={{color: "#BF2427"}}>※1以上の数値を入力してください</Typography>
                                                )}
                                        </>
                                        )}/>
                    </div>
                </form>
            </td>

            <td>
                <div className="delete">
                    <Fab sx={{backgroundImage: "url(/images/icon_delete.svg)"}}
                         onClick={() => handleOpItemDelete(opItem)}>
                    </Fab>
                </div>
            </td>
        </tr>)
    ;
};

const OpItem: React.FC = (props) => {
    let operationId = useOperationIdParam();
    const navigate = useNavigate();

    const scrollBaseRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLDivElement>(null);

    const {data, isFetching} = useGetOperationItemQuery({
        opId: operationId
    });

    const dispatch = useDispatch()

    useEffect(() => {
        let e = function () {
            let e = document.createElement("div");
            e.style.visibility = "hidden";
            e.style.overflow = "scroll";
            document.body.appendChild(e);
            let t = e.offsetWidth - e.clientWidth;
            document.body.removeChild(e)
            return t;
        }();

        if (tableRef.current != null) {
            tableRef.current.style.setProperty("--scrollbar-width", "".concat((-1 * e).toString(), "px"))
        }
    }, [tableRef]);

    const [isShowJanCode, setIsShowJanCode] = useState(false);

    const doScrollTop = () => {
        scrollBaseRef.current?.scroll(0, 0);
    }

  const handleRegisterViaList = () => {
    navigate(`/operation/${operationId}/op_item/select`);
  }

  // @ts-ignore
  return (
        <React.Fragment>
          <Box
              sx={{
                margin: "50px auto 50px",maxWidth: "1120px"
              }}>
            <Box
                sx={{
                  margin: "0 auto",
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 1,
                  m: 1,
                  borderRadius: 1
                }}>
              <Stack direction={"column"}>
                <Grid container alignItems={"center"} >
                  <Grid item>
                    <img src="./images/icon_additem.svg"
                         alt="物品の追加"
                         width="48"
                         height="48" />
                  </Grid>
                  <Grid item alignContent={"center"} alignItems={"center"} >
                    <Typography
                        color={"primary"}
                        fontSize={"large"}
                        sx={{
                          fontSize: "23px",
                          fontWeight: "700",
                        }}>物品の追加</Typography>
                  </Grid>

                  <Box marginLeft={"40px"}
                       sx={{
                         padding: "2.5px 1px 1px 10px",
                         border: "1px solid #cbcbcb",
                         backgroundColor: "white"
                       }}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography
                          color={"primary"}
                          fontSize={"large"}
                          sx={{
                            fontWeight: "700",
                          }}
                      >バーコードの入力</Typography>
                      <RecepoSwitch
                          checked={isShowJanCode}
                          onChange={(e) => setIsShowJanCode(e.target.checked)}
                      />
                    </Stack>
                  </Box>

                  <Grid item alignSelf={"revert-layer"} ></Grid>
                  <Grid item alignSelf={"revert-layer"}>
                  </Grid>
                </Grid>

              </Stack>
              <Box></Box>
              <Box>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={{ height: 80, fontSize: 20 }}
                    onClick={handleRegisterViaList}>一覧から登録</Button>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={{ height: 80, fontSize: 20, marginLeft: 3 }}
                    onClick={ () =>  dispatch(modalActions.open("opItemImport")) } >csvインポート</Button>
              </Box>
            </Box>

            <Box marginLeft={8.5} width={365}>
              { isShowJanCode ? (<JanCodeInput operationId={operationId} />): null }
            </Box>
          </Box>


          <div ref={scrollBaseRef}  >

            <div className="useitem__list">
              <table className="table__head">
                <thead>
                <tr>
                  <th>
                    <div>物品名称<br /><span>[ 読み ]</span></div>
                  </th>
                  <th>
                    <div>メーカー<br />商品コード</div>
                  </th>
                  <th>
                    <div>ロット番号<br />使用期限</div>
                  </th>
                  <th>
                    <div>数量</div>
                  </th>
                  <th>
                    <div>削除</div>
                  </th>
                </tr>
                </thead>
              </table>
              <Box
              sx={{
                  mb: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: "calc( 100vh - 365px )" ,
                  overflow: "hidden",
                  overflowY: "scroll",
                  width: "calc( 100% - var( --scrollbar-width) )",
                // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }} ref={tableRef}
            >
                <table className="table__body">
                  <tbody>
                  {data?.map((opItem, i) => (
                      <OperationItemLine
                          key={opItem.opItemId + "_" + opItem.opOtherItemId}
                          opItem={opItem}
                          onUpdated={ doScrollTop }/>
                  ))}
                  </tbody>
                </table>

              </Box>              
              <div className="useitem__table"
                   style={{height: "auto"}}
                   >
              </div>
            </div>
          </div>

          <OpItemCsvImportModal dialogName={"opItemImport"}/>

          <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={ isFetching }>
            <CircularProgress color="inherit" />
          </Backdrop>

        </React.Fragment>
  );
};

export default OpItem;
