import {createTheme} from "@mui/material";

export const mainTheme = createTheme({
    palette:{
        text: {
            primary: "#034c8c"

        },
        primary: {
            main: "#034c8c",
            light: "#9bbeff",
        },

        success: {
            main: "#00ee52",
        },
        warning: {
            main: "#ff9124",
            light : "#ffcd93",
        },

        secondary: {
            main: "#9bbeff",
            light : "#ffcd93",
        },

    },
    components: {
        MuiFab:{
            styleOverrides:{
                // secondary:{
                //     backgroundColor: "#9bbeff"
                // }
            }
        },
        MuiTabs:{
            styleOverrides: {
                indicator: {
                    height: "5px",
                    backgroundColor: "#ff9124"
                }
            }
        },
        MuiTab:{
            styleOverrides:{
                textColorSecondary:{
                    color: "#0000ff"
                },
                root:{
                    "&.Mui-selected" : {
                        color: "#ff9124"
                    },
                    color: "#ffcd93",
                    backgroundColor: "white",
                    fontSize: "25px",
                    fontWeight: "bolder",
                    height: "80px",
                    maxWidth:"unset",
                    margin: "auto",
                    flex: "1 0 auto"
                }
            }
        },

        MuiToggleButton:{
            styleOverrides: {
                root: {
                    "&.Mui-selected:hover": {
                    //     boarderLeftColor: "black",
                    //     backgroundColor: '#e8e8e8'
                    //     backgroundColor: "rgba(10, 10, 10, 0.1)"
                    },
                    "&.Mui-selected": {
                        backgroundColor: "rgba(10, 10, 10, 0.08)"
                    //     boarderLeftColor: "black",
                    //     backgroundColor: '#e8e8e8'
                    },
                    color: "#034c8c",
                    borderColor: "black",
                    fontWeight: "bold",
                    fontSize: "20px"
                }
            },
            defaultProps:{
            }
        },

        MuiSwitch:{
            defaultProps: {
                color: "success"
            }
        },
        // Name of the component
        MuiButtonBase: {
            defaultProps: {
                // The props to change the default for.
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
    },
});

