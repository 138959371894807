
import React from 'react';
import {EditBasicInfoContainer} from "../organisms/EditBasicInfoContainer";
import {useOperationIdParam} from "../../hooks/useOperationIdParameter";
import {useSelector} from "react-redux";
import {operationIdSelector} from "../../app/slices/operationSlice";

const BasicInfoEditPage: React.FC = (props) => {
    console.log("BasicInfoEditPage");

    let operationId = useOperationIdParam();
    if (operationId == null) {
        return (<></>);
    }

    const element =(<EditBasicInfoContainer operationId={operationId} />);

    return element;
};

export default BasicInfoEditPage;
