import React, {FC,  useState} from "react";
import {  useParams, useNavigate } from "react-router-dom";
import {
    BalanceItemSection,
    BalanceOperationShugi,
    BalanceShugiSection,
    GetBalanceResponse,
    OperationItems,
    useGetBalanceQuery,
    useSetOperationItemCaluculationStatusMutation,
    useSolveWarningMutation,
    WarningResource
} from "../../store/api";
import {useOperationIdParam} from "../../hooks/useOperationIdParameter";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Backdrop, Box, Checkbox, Chip, CircularProgress, FormControl,
    FormControlLabel, Grid, Radio, RadioGroup,
    StyledEngineProvider, ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";

type BalanceTab = "income" | "outgo";

const guardBalanceTab: (name: string) => BalanceTab | null = (name) =>
    (name === "income" || name === "outgo") ? name : null

const useConcreteBalanceTab: () => BalanceTab | null = () =>
    guardBalanceTab(useParams<{balanceTab: string}>().balanceTab ?? "income")

const Warning: FC<{
    opShugis: BalanceOperationShugi[],
    warnings: WarningResource[],
}> = ({ opShugis, warnings}) => {

    const operationId = useOperationIdParam();
    const [updatingShugiId, setUpdatingShugiId] = useState(0);

    const balanceQuery = useGetBalanceQuery({ opId: operationId },)
    const [ solveWarning, mutationOptions] = useSolveWarningMutation();

    const isUpdating = balanceQuery.isLoading || mutationOptions.isLoading;

    const onClick = (selectedShugiId: number, selected: BalanceOperationShugi[]) => {
        if (isUpdating) return;

        setUpdatingShugiId(selectedShugiId);

        solveWarning({
            opId: operationId,
            body: {
                opShugis: [
                    { opShugiId: selectedShugiId, isCalculate: true },
                    ...selected.filter( e => e.id !== selectedShugiId ).map((e) =>({ opShugiId: e.id, isCalculate: false}))
                ]
            }
        })
    }

    return (
        <div>
            <Typography fontSize={25} color={"error.main"}>同一算定できない項目があります</Typography>
            <Typography color={"primary.dark"}>下記の項目から主となる項目を選んでください</Typography>

            <Grid container direction={"column"}>
                    { warnings.filter( w => opShugis.filter( o => o.shugiId ===  w.id1 || o.shugiId === w.id2).length > 0).map( w => {
                        const warningShugis = opShugis.filter( o => o.shugiId ===  w.id1 || o.shugiId === w.id2);
                        const selected = warningShugis.filter(s => s.isCalculate)[0];

                        return (
                                <Grid item direction={"column"}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            value={selected?.id}
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group">
                                            { warningShugis.map(o => (
                                                <FormControlLabel
                                                                disabled={isUpdating}
                                                                onClick={event => onClick(o.id, warningShugis)  }
                                                                control={<Radio value={o.id} />}
                                                                label={o.name} />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                    { isUpdating && warningShugis.filter( e => e.id === updatingShugiId ).length > 0 && (<CircularProgress />)}
                                </Grid>
                        );
                })}
            </Grid>

        </div>);
}


const ShugiSection: FC<{opShugi: BalanceOperationShugi}> = ({opShugi}) => {

    const color = opShugi.relationShitType ==　"IGNORE" ?　"text.disabled"  : "text.primary" ;

    return (
        <React.Fragment>
            <Grid container sx={{textDecorationStyle: "revert"}}>
                <Grid item xs={9}>
                    <Typography marginLeft={3} fontSize={"x-large"} color={color}>

                        {opShugi.kubun}
                        {opShugi.name}
                        { (opShugi.relationShitType ==　"PRIMARY") && (
                            <Chip label={"主たる手術"} />
                        )}

                        { (opShugi.relationShitType ==　"SLAVE") && (
                            <Chip label={"従たる手術"} />
                        )}

                        {opShugi.kizami === 1 && (
                            <>
                                <input type="number" value={opShugi.amount} readOnly />{opShugi.unit}
                            </>
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography fontSize={"large"} color={color}>{ opShugi.point.toLocaleString() }</Typography>
                </Grid>
            </Grid>

            {opShugi.kasans.length > 0 && (
                <Box>
                    <Typography marginLeft={4} color={color} >注加算</Typography>

                    { opShugi.kasans.map( kasan => (
                        <Grid marginLeft={6}  key={kasan.id} container>
                            <Grid  item xs={9}>
                                <Typography fontSize={"large"} color={color}>
                                    {kasan.kubun}　{kasan.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} marginLeft={-6} >
                                <Typography fontSize={"large"} color={color}>
                                    +{kasan.sign}{kasan.point.toLocaleString()}{kasan.pointUnit}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            )}
        </React.Fragment>)
}

const BillingJokenShokanItem: FC<{item: OperationItems}> = ({item}) => {
    const operationId = useOperationIdParam();

    const balanceQuery = useGetBalanceQuery({ opId: operationId },)
    const [mutation, mutationOptions] = useSetOperationItemCaluculationStatusMutation();

    const isUpdating = balanceQuery.isLoading || mutationOptions.isLoading;


    const update = (tokuteiId: number, checked: boolean) =>{
        if (isUpdating) return;

        const opItemCount = item.operationTokutei.filter( (ot) => ot.tokuteiId === tokuteiId)[0]?.count ?? 0;

        mutation({
            operationId: operationId,
            opItemId: item.opItemId,
            tokuteiId: tokuteiId,
            body:{
                count: opItemCount + (checked ? -1 : 1)
            }
        });
    }

    return (
        <React.Fragment>
            {
                Array.from({length: item.quantity}).map( (_, i) => (
                    <Box paddingLeft={5}>
                        <Typography fontSize={"1.9em"}>{item.itemName}</Typography>

                        { item.tokuteis.map( (tokutei) => {
                            const isChecked = item.operationTokutei.filter( (ot) => ot.tokuteiId === tokutei.id && i < ot.count).length > 0
                            const color = isChecked ? "text.primary" :  "text.disabled";

                            return (
                                <Grid container>
                                    <Grid item sm={8}>
                                        <Box marginLeft={5.5} sx={{color: color}}>
                                            <FormControlLabel
                                                data-testid={"label"}
                                                disabled={ isUpdating }
                                                color={ item.isSelectedTokuteiItem ? "default" :  "disable"}
                                                control={(<Checkbox data-testid={"checkbox"}
                                                                    onClick={ () => update( tokutei.id, isChecked )}
                                                                    checked={ isChecked } />)}
                                                label={(
                                                    <Typography fontSize={"1.7em"}>
                                                        { tokutei.code } { tokutei.name }
                                                    </Typography>)} />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Typography fontSize={"1.7em"} textAlign={"right"}>
                                            { tokutei.cost.toLocaleString() }
                                        </Typography>
                                    </Grid>

                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={isUpdating}>
                                        <CircularProgress color="inherit" />
                                    </Backdrop>

                                </Grid>

                        )})}
                    </Box>
                ))
            }
        </React.Fragment>)
}


const BillingItem: FC<{item: OperationItems}> = ({item}) => {
    const operationId = useOperationIdParam();

    const balanceQuery = useGetBalanceQuery({ opId: operationId },)
    const [mutation, mutationOptions] = useSetOperationItemCaluculationStatusMutation();

    const isUpdating = balanceQuery.isLoading || mutationOptions.isLoading;


    const update = (tokuteiId: number, checked: boolean) =>{
        if (isUpdating) return;

        const opItemCount = item.operationTokutei.filter( (ot) => ot.tokuteiId === tokuteiId)[0]?.count ?? 0;

        mutation({
            operationId: operationId,
            opItemId: item.opItemId,
            tokuteiId: tokuteiId,
            body:{
                count: opItemCount + (checked ? -1 : 1)
            }
        });
    }


    return (
        <React.Fragment>
            {
                Array.from({length: item.quantity}).map( (_, i) => (
                    <Box paddingLeft={5}>
                        <Typography fontSize={"1.9em"}>{item.itemName}</Typography>

                        { item.tokuteis.map( (tokutei) => {
                            const isChecked = item.operationTokutei.filter( (ot) => ot.tokuteiId === tokutei.id && i < ot.count).length > 0
                            const color = isChecked ? "text.primary" :  "text.disabled";

                            return (
                                <Grid container>
                                    <Grid item sm={8}>
                                        <Box marginLeft={5.5} sx={{color: color}}>
                                            <FormControlLabel
                                                data-testid={"label"}
                                                disabled={ isUpdating }
                                                color={ item.isSelectedTokuteiItem ? "default" :  "disable"}
                                                control={(<Checkbox data-testid={"checkbox"}
                                                                    onClick={ () => update( tokutei.id, isChecked )}
                                                                    checked={ isChecked } />)}
                                                label={(
                                                    <Typography fontSize={"1.7em"}>
                                                        { tokutei.code } { tokutei.name }
                                                    </Typography>)} />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Typography fontSize={"1.7em"} textAlign={"right"}>
                                            { tokutei.cost.toLocaleString() }
                                        </Typography>
                                    </Grid>
                                </Grid>

                            )})}
                        { item.yakuhinId != null && (
                                <Grid container>
                                    <Grid item sm={8}>
                                        <Box marginLeft={5.5} sx={{color: "text.primary"}}>
                                            <Typography fontSize={"1.7em"}>
                                                { item.yakuhin_receipt_code } { item.yakuhin_receipt_name }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Typography fontSize={"1.7em"} textAlign={"right"}>
                                            { item.amount?.toLocaleString() }
                                        </Typography>
                                    </Grid>
                                </Grid>
                         )}
                    </Box>
                ))
            }
        </React.Fragment>)
}

const PaymentItem: FC<{item: OperationItems}> = ({item}) => {
    return (
        <React.Fragment>
            <Box paddingLeft={5}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography fontSize={"1em"} >{item.itemCode}</Typography>
                        <Typography fontSize={"1.9em"}>{item.itemName}</Typography>
                    </Grid>
                    <Grid item xs={2}  sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontSize={"1.5em"}>{item.quantity} x {item.cost.toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={2}  sx={{ display: 'flex', alignItems: 'center' }}><Typography fontSize={"1.5em"}>¥{(item.quantity * item.cost).toLocaleString()}</Typography></Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}


/**
 * 収入の部の医療材料欄
 *
 * @param item
 * @constructor
 */
const BalanceIncomeItemSection: FC<{item: OperationItems}> = ({item}) => {
    if (item.seikyuNaiyou === "条件償還") {
        return (<BillingJokenShokanItem item={item} />)
    } else {
        return (<BillingItem item={item} />);
    }
}


/**
 * 支出の部の医療材料欄
 *
 * @param item
 * @constructor
 */
const BalancePaymentItemSection: FC<{item: OperationItems}> = ({item}) => {
        return (<PaymentItem item={item} />);
}


const ItemSection: FC<{section: BalanceShugiSection | BalanceItemSection}> = ({section}) => {
    const [expanded, setExpanded] = useState(false);

    return (<React.Fragment key={section.name}>
        <Accordion
            expanded={expanded}
            onChange={() => {setExpanded(!expanded)}}
            style={{
               margin:"0"
            }}>
            <AccordionSummary component={"dl"} className={"group__title"} style={{
                color:"#034c8c",
                margin:"0",
                padding:" 5px 0 0 20px"
            }}>
                <Grid container>
                    <Grid item xs={8}><Typography fontSize={"x-large"}>{section.name}</Typography></Grid>
                    <Grid item xs={4}><Typography fontSize={"large"}>¥{section.subTotal.toLocaleString()}</Typography></Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {section.kind === "shugi" && section.operationShugis.map(opShugi => (
                    <React.Fragment key={opShugi.id}>
                        <ShugiSection opShugi={opShugi}/>
                        {section.kasans.map(kasan => (
                            <dl key={kasan.id} className="group__item">
                                <dt className="group__itemLabel">{kasan.name}</dt>
                                <dd className="group__itemData">{kasan.sign}{kasan.point.toLocaleString()}{kasan.pointUnit}</dd>
                            </dl>
                        ))}
                    </React.Fragment>
                ))}

                {section.kind === "item" && section.items.map(item => (
                    <React.Fragment key={item.itemId}>
                        <BalanceIncomeItemSection item={item}/>
                    </React.Fragment>
                ))}
            </AccordionDetails>
        </Accordion>
    </React.Fragment>)
};

const PaymentSection: FC<{section: BalanceShugiSection | BalanceItemSection}> = ({section}) => {
    const [expanded, setExpanded] = useState(false);

    return (<React.Fragment key={section.name}>
        <Accordion className={""} expanded={expanded} onChange={() => {setExpanded(!expanded)}}
                   style={{
                       margin:"0"
                   }}>
            <AccordionSummary component={"dl"} className={"group__title"} style={{
                color:"#034c8c",
                margin:"0",
                padding:" 5px 0 0 20px"
            }}>
                <dt className="group__label">{section.name}</dt>
                <dd className="group__data">¥{section.subTotal.toLocaleString()}</dd>
            </AccordionSummary>
            <AccordionDetails>
                {section.kind === "shugi" && section.operationShugis.map(opShugi => (
                    <React.Fragment key={opShugi.id}>
                        <ShugiSection opShugi={opShugi}/>
                        {section.kasans.map(kasan => (
                            <dl key={kasan.id} className="group__item">
                                <dt className="group__itemLabel">{kasan.name}</dt>
                                <dd className="group__itemData">{kasan.sign}{kasan.point.toLocaleString()}{kasan.pointUnit}</dd>
                            </dl>
                        ))}
                    </React.Fragment>
                ))}

                {section.kind === "item" && section.items.map(item => (
                    <React.Fragment key={item.itemId}>
                        <BalancePaymentItemSection item={item}/>
                    </React.Fragment>
                ))}
            </AccordionDetails>
        </Accordion>
    </React.Fragment>)
};


const BalanceIncome: React.FC<{data: GetBalanceResponse}> = ({data}) => {
    
  const balanceOperationShugis = data.operation.income.sections
      .filter( section => section.kind === "shugi" )
      .map( e => e as BalanceShugiSection )
      .reduce((sectionA, sectionB) => {
          return [ ...sectionA, ...sectionB.operationShugis ];
      }, [] as BalanceOperationShugi[] )

    return (
        <div className="income__area">

            { data.warnings.length > 0 && (
                <Warning opShugis={ balanceOperationShugis } warnings={ data.warnings ?? [] } />
            )}

            <div className="income__total">合計 ¥{ data.operation.income.totalIncome.toLocaleString() }</div>
            <div className="group__area">
                <div className="group">
                    { data.operation.income.sections.map(section => (
                        <ItemSection key={section.name} section={section} />
                    ))}
                </div>
            </div>
        </div>)
}

const BalanceOutgo: React.FC<{data: GetBalanceResponse}> = ({data}) => {
    return (
        <div className="income__area">
             <div className="income__total">合計 ¥{data.operation.outgo.totalCost.toLocaleString() }</div>
             <div className="group__area">
                 { data.operation.outgo.sections.map(section => (
                     <PaymentSection key={section.name} section={section} />
                 ))}
             </div>
        </div>)
}

const Balance: React.FC = (props) => {
    const navigate = useNavigate();
    // const [timing, updateTiming] = useState(0);

    const handleToIncome = (ex: any, b : any = null) => {
        if (b !=null) {
            navigate(`/operation/${operationId}/balance/${b}`)
        }
    }

    let operationId = useOperationIdParam();
    const balanceTab = useConcreteBalanceTab();

    if (balanceTab == null) {
        handleToIncome("income")
    }

    const response = useGetBalanceQuery({ opId: operationId }, {})
    const { data, isFetching, refetch } = response;


  return (
      <StyledEngineProvider injectFirst>

            <React.Fragment>

                <Box marginTop={"40px"} display={"flex"} width={"100%"} justifyContent={"center"}>
                    <Box width={"400px"}>
                        <ToggleButtonGroup
                            value={balanceTab}
                            exclusive
                            onChange={handleToIncome}
                            fullWidth
                        >
                            <ToggleButton value="income">収入</ToggleButton>
                            <ToggleButton value="outgo">支出</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Box>

                    <div className="m-splitColumns__main">

                        {!!data && balanceTab === "income" && <BalanceIncome data={data} />}
                        {!!data && balanceTab === "outgo" && <BalanceOutgo data={data} />}

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isFetching}>
                            <CircularProgress color="inherit" />
                        </Backdrop>

                    </div>
                </React.Fragment>
      </StyledEngineProvider>)
};

export default Balance;
