import { createSlice, PayloadAction  } from '@reduxjs/toolkit';
import {AppDispatch, RootState} from '../../app/store';
import {OPERATION_STATE } from '../../types';
import {
  OperationResult,
  useDepartmentListApiQuery,
  useDoctorListApiQuery,
  useGetOperationDetailApiQuery
} from "../../store/api";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

export const initialState: OPERATION_STATE = {
  selectedOperationId: null,
  editedOperation: {
    id: 0,
    externalOpId: '',
    operationDate: '',
    responsibleDivision:"",
    startTime: "00:00",
    departmentId: 0,
    doctorId: 0,
    memo: "",
  },

  selectedOperation: {
    id: 0,
    externalOpId: '',
    operationDate: '',
    startTime: "00:00",
    departmentId: 0,
    doctorId: 0,
    responsibleDivision:"",
    memo: ""
  },
};

export const operationSlice = createSlice({
  name: 'operation',
  initialState,
  reducers: {
    selectOperationId(state, action: PayloadAction<number| null>) {
      if (state.selectedOperationId !== action.payload) {
        state.selectedOperationId = action.payload
      }
    },

    selectOperation(state, action: PayloadAction<OperationResult>) {
      if (state.selectedOperationId !== action.payload.id) {
        state.selectedOperationId = action.payload.id
      }

      state.selectedOperation = { ...action.payload};
      state.editedOperation = {
        ...action.payload
      };
    },

    editSelectedOperationsBasicInfo(state, action: PayloadAction<Omit<OperationResult, "id">>) {
      if (state.editedOperation != null) {
        state.editedOperation = Object.assign(state.editedOperation, action.payload)
      }
    },

    updateWeight(state, action: PayloadAction<number>) {
      if (state.editedOperation != null) {
        state.editedOperation.weight = action.payload;
      }
    },

    resetOperation(state) {
      state.editedOperation = initialState.selectedOperation;
      state.selectedOperation = initialState.selectedOperation;
    },

  },
});

export const {
  selectOperationId,
  selectOperation,
  resetOperation,
  editSelectedOperationsBasicInfo,
  updateWeight
} = operationSlice.actions;


export const operationIdNullableSelector = (state: RootState) => {
  return state.operation.selectedOperationId;
}
export const operationIdSelector = (state: RootState) => {
  return state.operation.selectedOperationId;
}

export const selectSelectedOperation = (state: RootState) => {
  return state.operation.selectedOperation;
}
export const selectEditedOperation = (state: RootState) => state.operation.editedOperation;
export default operationSlice.reducer;

/**
 *
 */
export const useDeselectOperation = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  return () => {
    // Operationを選択する
    dispatch(selectOperationId(null));

  }
}
/**
 *
 */
export const useSelectOperation = () => {
  // const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  return (operationId: number) => {

    // Operationを選択する
    dispatch(selectOperationId(operationId));

    // 移動
    // navigate("/operation/" + operationId + "/detail");
  }
}


export const useSelectedOperationHook = (operationId: number) => {

  const dispatch: AppDispatch = useDispatch();

  const { data: departments } = useDepartmentListApiQuery();
  const { data: doctors } = useDoctorListApiQuery();

  const { data } = useGetOperationDetailApiQuery({
    opId: operationId
  });

  useEffect( () => {
    if (data != null && departments != null && doctors != null) {
      dispatch(selectOperation(data))
    }
  },[data, departments, doctors])

  return useSelector(selectSelectedOperation);
}


