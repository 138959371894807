
import {createSlice, ThunkAction} from "@reduxjs/toolkit";
import {
    api,
    DeleteOperationShugiApiArg,
    OperationKasan,
    OperationShugiResource,
    ReorderOperationShugiApiArg,
    SetOperationItemCaluculationStatusApiArg,
    SolveWarningApiArg,
    useSetOperationItemCaluculationStatusMutation
} from "../../store/api";

type StateType = {
};

/** selectors */

/** mutations */

export const optimisticUpdateEndpoint = <Req, Res> (update: (request: Req) => ThunkAction<any, any, any, any>) => (done: (data: Res) => void) => (failed: (e: Error) => void) => (endpoint: any) => {
    endpoint.onQueryStarted = async (request: Req, { dispatch, queryFulfilled }: {dispatch: (re: ThunkAction<any, any, any, any>) => void, queryFulfilled: Promise<any> }) => {
        dispatch( update(request) );

        try {

            const { data: updatedPost } = await queryFulfilled;

            done(updatedPost)

        } catch (e) {

            failed(e as Error)

        }
    }
}

const getOperationSolveWarningThunkAction = (request: SolveWarningApiArg) => {

    return api.util.updateQueryData('getBalance', {opId: request.opId} , (draft) => {

        draft.operation.income.sections = draft.operation.income.sections.map( (section) => {

            if ( section.kind === "shugi" ) {
                section.operationShugis = section.operationShugis.map( (shugi) => {
                    for ( const solvedShugis of request.body.opShugis ?? [] ) {
                        if ( shugi.id === solvedShugis.opShugiId) {
                            shugi.isCalculate = solvedShugis.isCalculate
                            console.log("aaa");
                        }
                    }

                    return {...shugi};
                })

            }

            return { ...section }
        })
    })
}

const setOperationItemCaluculationStatusThunkAction = (request: SetOperationItemCaluculationStatusApiArg) => {

    return api.util.updateQueryData('getBalance', { opId: request.operationId } , (draft) => {

        draft.operation.income.sections = draft.operation.income.sections.map( (section) => {

            if ( section.kind === "item" ) {
                section.items = section.items.map( (item) => {
                    if ( item.opItemId === request.opItemId) {
                    }

                    return {...item};
                })
            }

            return { ...section }
        })
    })
}


export const CustomGetBalanceAPI = optimisticUpdateEndpoint( getOperationSolveWarningThunkAction )( () => {} )( () => {} )

export const CustomSetOperationItemCaluculationStatusAPI = optimisticUpdateEndpoint( setOperationItemCaluculationStatusThunkAction )( () => {} )( () => {} )



/** actions */


export const balanceSlice = createSlice({
    name: "opShugi",
    initialState: () => {
        return {

        } as StateType;
    },
    reducers: {
    },
});




