import {Alert, Snackbar} from "@mui/material";
import React from "react";
import {useSelector} from "react-redux";
import {popToastMessages} from "../../app/slices/ui/toastSlice";

export const ToastBlock = () => {
    const toastMessages = useSelector(popToastMessages("deleteOperation"));

    return (
        <>
            { toastMessages.length > 0 && toastMessages.map( (message,o) => (
                <Snackbar
                    open={true}
                    anchorOrigin={{ vertical: 'top', horizontal: "center" }}
                    key={o}
                    sx={{ marginTop: 10 }} >
                    <Alert severity={message.severity} sx={{ width: '100%' }}>
                        {message.toastText}
                    </Alert>
                </Snackbar>)
            )}
        </>
    );
}