
import { useAuth0 } from '@auth0/auth0-react';
import { Inbox, Logout, Mail } from '@mui/icons-material';
import { Button, Divider, Drawer, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {modalActions, isOpen} from '../../app/slices/modalSlice'

const MenuPage : React.FC = () => {
  const dispatch = useDispatch();
  const isoepn   = useSelector(isOpen("drawer"));

  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();

  const doLogout = () => {
    localStorage.setItem("lastTenant", user?.org_id);

    logout({
        returnTo: window.location.origin + "/"
    });
  }

    const list = () => (
        <Box
          role="presentation"
          sx={{width: "350px"}}
        >
          <List>
          </List>
          <Divider />
          <List>
              <ListItem disablePadding>
                <ListItemButton onClick={ () => doLogout() }>
                  <ListItemIcon >
                    <Logout />
                  </ListItemIcon>
                  <ListItemText >ログアウト</ListItemText>
                </ListItemButton >
              </ListItem>
          </List>
        </Box>
      );
    
    return (
        <Drawer
        anchor={"left"}
        open={ isoepn }
        onClose={ ()=> { dispatch(modalActions.close()) } }
      >
        {list()}
      </Drawer>
    );
}


export default MenuPage;
