import React, {FC, useEffect, useRef, useState} from "react";
import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid, TextField
} from "@mui/material";
import {usePostOperationImportMutation} from "../../store/api";
import {useDispatch, useSelector} from "react-redux";
import {isOpen, modalActions} from "../../app/slices/modalSlice";
import {DatePicker, DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";


export const DatePickerModal: FC<{
    dialogName: string, DefaultStartValue: string, DefaultEndValue: string, onChange:(s: string, e: string)=>void, title: string
}> = ({dialogName, DefaultStartValue, DefaultEndValue, onChange, title}) => {
    const open:boolean = useSelector(isOpen(dialogName));
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(modalActions.close());
    }

    const [start, setStart]  = useState<string>(DefaultStartValue);
    const [end, setEnd]  = useState<string>(DefaultEndValue);

    useEffect(()=> {
        setStart(DefaultStartValue)
        setEnd(DefaultEndValue)
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <DialogTitle id="alert-dialog-title">
                検索期間
            </DialogTitle>

            <DialogContent>
                <Grid container>
                    <Grid item xs={6}>
                        検索開始日
                    </Grid>
                    <Grid item xs={6}>
                        検索終了日
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider
                            dateFormats={{ fullDate: "yyyy-mm-dd" }} dateAdapter={AdapterDateFns} adapterLocale={ja}>
                            <DesktopDatePicker
                                renderInput={(props) => (
                                    <TextField {...props}  />)}
                                onChange={(e) => {
                                    setStart( e ?? "");
                                }}
                                value={start}/>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider
                            dateFormats={{ fullDate: "yyyy-mm-dd" }} dateAdapter={AdapterDateFns} adapterLocale={ja}>
                            <DesktopDatePicker
                                renderInput={(props) => (
                                    <TextField {...props}  />)}
                                onChange={(e) => {
                                    setEnd( e ?? "");
                                }}
                                value={end}/>
                        </LocalizationProvider>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>キャンセル</Button>
                <Button onClick={ () =>{
                    onChange( dayjs(start).format("YYYY-MM-DD").toString(), dayjs(end).format("YYYY-MM-DD").toString());
                    handleClose();
                }} >検索</Button>
            </DialogActions>
        </Dialog>);
}
