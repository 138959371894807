import React, {FC, useRef, useState} from "react";
import {
    KasanOption,
    OperationKasan,
    OperationShugiResource, OperationShugisOperation,
    ShugiType, useDeleteOperationShugiMutation,
    useFindOperationShugiQuery, useGetOperationShugiQuery, usePostOperationKasanOptionMutation,
    usePostOperationShugiMutation, usePutOperationApiMutation, useReorderOperationShugiMutation
} from "../../store/api";
import {useOperationIdParam, useShugiTabParam} from "../../hooks/useOperationIdParameter";
import {
    Backdrop,
    Box,
    Button,
    Checkbox,  CircularProgress, Fab,
    FormControlLabel,
    FormGroup,
    TextField, ToggleButton, ToggleButtonGroup, Typography,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete'
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {useOpShugiListByTypeHook} from "../../hooks/opShugi/opShugiHooks";
import {useDispatch} from "react-redux";
import {
    updateWeight,
    useSelectedOperationHook
} from "../../app/slices/operationSlice";
import {Controller, useForm} from "react-hook-form";
import {Add, HorizontalRule} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {fi} from "date-fns/locale";


type UpdateOptionFunction = (id: number|null) => void;

type ShugiTabList = "shujutsu" | "masui" | "yuketsu"

const mapOrDefault = <T,>(filter: (f: T) => boolean) => (map: (opShugi: T) => T) => (opShugi: T) => {
    if (filter(opShugi)) {
        return map(opShugi)
    } else {
        return opShugi;
    }
}

const ShugiTab: React.FC<{value: ShugiTabList, onSelectTab: (tabName: ShugiTabList) => void}> = ({ value, onSelectTab}) => {
    return (<div>
        <ToggleButtonGroup
            value={value}
            fullWidth
            exclusive
            onChange={ (e, v) => onSelectTab(v) }
            aria-label="text alignment"
        >
            <ToggleButton value="shujutsu" aria-label="left aligned">
                手術
            </ToggleButton>
            <ToggleButton value="masui" aria-label="left aligned">
                麻酔
            </ToggleButton>
            <ToggleButton value="yuketsu" aria-label="left aligned">
                輸血
            </ToggleButton>

        </ToggleButtonGroup>
    </div>)
}



/**
 * きざみ値の入力欄を表示する
 *
 * @param {shugi: SHUGI} props
 */
const WeightInput: FC = (props) => {
    const operationId = useOperationIdParam();

    const selectedOperation = useSelectedOperationHook(operationId);

    const dispatch = useDispatch();
    const [putOperationApiMutation] = usePutOperationApiMutation();

    const handleWeight = (weight: number) => {

        dispatch(updateWeight(weight))

        putOperationApiMutation({
            opId: operationId,
            body: {
                operation: {
                    weight: weight
                },
                mask: [ "weight" ]
            }
        });
    }

    return (
        <React.Fragment>
            <dl className="procedure__block">
                <dt>年齢</dt>
                <dd>
                    <span>6歳未満
                        <input
                            type={"number"}
                            value={ selectedOperation.weight }
                            width={100}
                            onChange={ (e) => handleWeight(Number.parseInt(e.target.value ?? "0")) } />Kg
                    </span>
                </dd>
            </dl>
        </React.Fragment>);

}

/**
 * きざみ値の入力欄を表示する
 *
 * @param {shugi: SHUGI} props
 */
const AmountInput: FC<{opShugi: OperationShugiResource, onChange: (v: number) => void} > = (props) => {

    // const handleChange = (data: any) => {
    //     if (timeout != null)
    //         clearTimeout(timeout);
    //
    //     setTimeout( window.setTimeout(() => {
    //         handleBlur();
    //     },1000));
    //
    //     return true;
    // }

    if (props.opShugi.shugi.kizami === 1) {
        return (
            <React.Fragment>
            </React.Fragment>);
    } else {
        return (<React.Fragment />);
    }
}


const ShugiLine : FC<{ row: OperationShugiResource }> = ({ row }) => {
    let operationId = useOperationIdParam();

    const { data: opShugisOpration} = useGetOperationShugiQuery({opId: operationId }, { refetchOnMountOrArgChange: true })

    const [ postOperationShugi ] = usePostOperationShugiMutation()
    const [ deleteMutation ] = useDeleteOperationShugiMutation();

    const formRef = useRef<HTMLFormElement>(null);

    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        trigger
    } = useForm({
        mode: 'all',
        reValidateMode:"onChange",
        defaultValues: {
            amount: "" + row.amount ,
        },
    });

    const updateOpShugiAmount = (amount: number) => (opShugi: OperationShugiResource) => ({ ...opShugi, amount})


    const onSubmit = handleSubmit(({amount}) => {
        if (errors.amount != null ) return ;

        if (opShugisOpration?.operationShugis != null) {
            const num =Number.parseInt(amount);
            const newOpShugis = opShugisOpration?.operationShugis?.map(mapOrDefault<OperationShugiResource>( (e) => e.id === row.id )(updateOpShugiAmount(num)));

            postOperationShugi({
                opId: operationId,
                body: {
                    opId: operationId,
                    opShugis: newOpShugis
                }
            })
        }
    })

    const {onBlur, ...registers} = register("amount", {
        validate: (v) => {
            if (/^[0-9]+$/.test(v) ) {
                const num = Number.parseInt(v);
                if ( (row.shugi.kizami_under??0) <= num && num <= (row.shugi.kizami_limit ?? 0) )
                    return true
            }
            return false
        }
    })

    const controller = (
        <Box display={"inline-block"} marginLeft={5}>
            <form onSubmit={(event) => {
                event.preventDefault();
                onSubmit(event);
            }} ref={formRef}>
                <Controller control={control}
                            name={"amount"}
                            render={({field}) => (
                                <TextField
                                    {...registers}

                                    variant={"outlined"}

                                    onBlur={(ev) => {
                                        onBlur(ev)

                                        //submit
                                        ev.target.form?.requestSubmit();
                                        return
                                    }}

                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    size="small"

                                    sx={() => ({
                                        "& fieldset": {},

                                        "& input": {
                                            width: "100px",
                                            textAlign: "right",
                                            padding: "5",
                                        },

                                        "&": {
                                            verticalAlign: "baseline",
                                            padding: "0",
                                        },
                                    })}

                                />)}
                />
            </form>
        </Box>)

    return (
        <Box>
            <Box display={"inline-block"}
                 color={errors.amount != null ? "#BF2427" : "primary"}
                 maxWidth={"640px"}
            >
                <Typography display={"inline"} fontSize={"x-large"}>{row.shugi.kubun} {row.shugi.name}</Typography>
            </Box>

            {row.shugi.kizami === 1 && (
                <>
                    {controller}
                </>
            )}

            <Box display={"inline"} marginLeft={5}>
                <Button onClick={() => deleteMutation({opId: operationId, opShugiId: row.id})} color={"error"}>
                    <DeleteIcon/>
                </Button>
            </Box>
            {errors.amount != null && (
                <Typography fontSize={"small"}
                            color={"#BF2427"}>{row.shugi.kizami_under} 〜 {row.shugi.kizami_limit}の数値を入力してください</Typography>
            )}


        </Box>)

}

/**
 * 所定点数の一覧
 */
const BasePointList: FC<{
    name: string,
    type: ShugiType,
    opShugis: OperationShugiResource[],
    handleStartRegister: (type: ShugiType, isFullList: boolean) => void,
    handleUpdateKasan: (id: number) => (kasanId: number) => (checked: boolean) => void,
}> = ({name, type, handleStartRegister, handleUpdateKasan}) => {
    const opId = useOperationIdParam();
    const opShugis = useOpShugiListByTypeHook(type);
    const [reorderMutation] = useReorderOperationShugiMutation();

    if (opShugis == null) {
        return (<></>)
    }

    const reorder = (rows: OperationShugiResource[], type: ShugiType, startIndex: number, endIndex: number) => {
        const others = Array.from(rows?.filter(e => e.type !== type));
        const result = Array.from(rows?.filter(e => e.type === type));

        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return [ ...others, ...result ];
    };

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const newOrder = reorder(opShugis, type, source.index, destination.index);

        reorderMutation({
            opId: opId,
            body: newOrder.map( (opShugis, index) => ({ opShugiId: opShugis.id, order: index}))
        })
    };

    return (
        <>
            <h2>{name}</h2>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"dndTableBody"}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {opShugis.map( (row, index) => (
                                <Draggable
                                    draggableId={row.shugi.name}
                                    index={index}
                                    key={row.id}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            key={row.id}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps} >

                                            <ShugiLine row={row} />

                                            { row.kasans.length > 0 && (
                                                <dl className="procedure__block">
                                                    <dt>注加算</dt>
                                                    <dd>
                                                        {row.kasans.map( (kasan: OperationKasan) => {
                                                            return (
                                                                <label className="procedure__checkbox" key={kasan.id}>
                                                                    <input type="checkbox"
                                                                           checked={kasan.isUsed}
                                                                           onChange={(event) => handleUpdateKasan(row.id)(kasan.id)(event.target.checked)}
                                                                    />
                                                                    <span>{kasan.kubun} {kasan.name}</span>
                                                                </label>
                                                            )
                                                        })}
                                                    </dd>
                                                </dl>
                                            )}
                                            <Box mb={10}></Box>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Fab variant="extended" onClick={() => handleStartRegister(type, opShugis?.length > 0) } color={"secondary"} sx={{height: "50px"}}>
                <Box display={"flex"} alignItems={"center"}>
                    <Add />
                    <Typography marginLeft={2} fontSize={"large"}>
                        {opShugis?.filter(e => e.type === type).length > 0 ? "追加" : "登録"}
                    </Typography>
                </Box>
            </Fab>
        </>)
}

const KasanOptionList: FC<{name: string, groupName:string, kasanOptions: KasanOption[], onUpdateOption: UpdateOptionFunction}> = (props) => {
    return (
        <dl className="procedure__block">
            <dt>{props.name}</dt>
            <dd>
                <div className="procedure__radiolist">
                    <label><input type="radio" name={props.groupName} /><span>なし</span></label>
                    {props.kasanOptions.map(kasanOption => (
                        <label key={kasanOption.id}>
                            <input type="radio"
                                   name={props.groupName}
                                   defaultChecked={kasanOption.is_used}
                                   onClick={() => props.onUpdateOption(kasanOption.id) } /><span>{ kasanOption.name }</span></label>
                    ))}
                </div>
            </dd>
        </dl>);
}

/**
 * 手技一覧（チェック選択）
 *
 * @param props
 * @constructor
 */
const ShugiCheckList: FC<{
    name: string,
    items: OperationShugiResource[],
    opShguis: OperationShugiResource[],
}> = (props) => {

    const operationId = useOperationIdParam();

    const [ postOperationShugi ] = usePostOperationShugiMutation();

    const [ deleteMutation ] = useDeleteOperationShugiMutation();

    const handleRegister = (selected: OperationShugiResource) => {
        postOperationShugi({
            opId: operationId,
            body: {
                opId: operationId,
                opShugis: [...props.opShguis, selected ]
            }
        })
    }

    const handleDelete = (opShuigis: Array<OperationShugiResource>) => {
        opShuigis.forEach((opShugi) => {
            deleteMutation({opId: operationId, opShugiId: opShugi.id })
        })
    }

    return (
        <dl className="procedure__block">
            <dt>{props.name}</dt>
            <dd>
                <FormGroup>
                    { props.items.map(kasanOption => {
                        const isChecked = props.opShguis.filter( opShugis => opShugis.shugi.id === kasanOption.shugi.id ).length > 0;
                        return (
                            <FormControlLabel control={<Checkbox checked={ isChecked }
                                                                 onClick={ () => isChecked ? handleDelete( props.opShguis.filter( opShugis => opShugis.shugi.id === kasanOption.shugi.id ))
                                                                                           : handleRegister(kasanOption) }
                            />}
                                              label={ kasanOption.shugi.kubun + " " + kasanOption.shugi.name}
                            />)
                    })}
                </FormGroup>
            </dd>
        </dl>);
}

/***
 * 手術所定点数
 *
 * @constructor
 */
const OperationShugiShujitsuList: FC<{
    opShugisOpration: OperationShugisOperation
    handleStartRegister: (type: ShugiType, isFullList: boolean)=>void,
    handleUpdateKasan: (id: number) => (kasanId: number) => (checked: boolean) => void,
    handleUpdateOption: (type: string) => (id: number|null) => void
}> = ({handleStartRegister, opShugisOpration, handleUpdateKasan, handleUpdateOption}) => {
    return (
        <div className="m-splitColumns__editor procedure">
            <div className="m-splitColumns__main">
                <div className="procedure__area">
                    <BasePointList name={"手術"}
                                   opShugis={opShugisOpration.operationShugis}
                                   type={"shugi"}
                                   handleStartRegister={handleStartRegister}
                                   handleUpdateKasan={handleUpdateKasan}/>

                    <BasePointList name={"処置"} opShugis={opShugisOpration.operationShugis}
                                   type={"shochi"}
                                   handleStartRegister={handleStartRegister}
                                   handleUpdateKasan={handleUpdateKasan} />

                    <BasePointList name={"検査"}
                                   opShugis={opShugisOpration.operationShugis}
                                   type={"kensa"}
                                   handleStartRegister={handleStartRegister}
                                   handleUpdateKasan={handleUpdateKasan} />

                    <h2>年齢加算・時間加算</h2>

                    <KasanOptionList
                        name="年齢加算"
                        groupName={"name01"}
                        kasanOptions={opShugisOpration.nenreiKasanOptions}
                        onUpdateOption={handleUpdateOption("nenrei")} />

                    <KasanOptionList
                        name="時間外等加算"
                        groupName={"name02"}
                        kasanOptions={opShugisOpration.jikangaiKasanOptions}
                        onUpdateOption={handleUpdateOption("jikangai")} />
                </div>
                <br/>            <br/>            <br/>            <br/>            <br/>            <br/>
            </div>
        </div>)
}

/**
 * 麻酔所定点数
 *
 * @constructor
 */
const OperationShugiMasuiList: FC<{
    opShugisOpration: OperationShugisOperation
    handleStartRegister: (type: ShugiType, isFullList: boolean)=>void,
    handleUpdateKasan: (id: number) => (kasanId: number) => (checked: boolean) => void,
    handleUpdateOption: (type: string) => (id: number|null) => void
}> = ({handleStartRegister, opShugisOpration, handleUpdateKasan, handleUpdateOption}) => {
    return (
        <div className="m-splitColumns__editor procedure">
            <div className="m-splitColumns__main">
                <div className="procedure__area">
                    <BasePointList name={"麻酔"}
                                   opShugis={opShugisOpration.operationShugis}
                                   type={"masui"}
                                   handleStartRegister={handleStartRegister}
                                   handleUpdateKasan={handleUpdateKasan}/>

                    <h2>年齢加算・時間加算</h2>

                    <KasanOptionList
                        name="年齢加算"
                        groupName={"name01"}
                        kasanOptions={opShugisOpration.nenreiKasanOptions}
                        onUpdateOption={handleUpdateOption("nenrei")} />

                    <KasanOptionList
                        name="時間外等加算"
                        groupName={"name02"}
                        kasanOptions={opShugisOpration.jikangaiKasanOptions}
                        onUpdateOption={handleUpdateOption("jikangai")} />
                </div>
                <br/>            <br/>            <br/>            <br/>            <br/>            <br/>
            </div>
        </div>)
}

/**
 * 輸血所定点数
 *
 * @constructor
 */
const OperationShugiYuketsuList: FC<{
    opShugisOpration: OperationShugisOperation
    handleStartRegister: (type: ShugiType, isFullList: boolean)=>void,
    handleUpdateKasan: (id: number) => (kasanId: number) => (checked: boolean) => void,
    handleUpdateOption: (type: string) => (id: number|null) => void
}> = ({handleStartRegister, opShugisOpration, handleUpdateKasan, handleUpdateOption}) => {

    const operationId = useOperationIdParam();

    const { data : yuketsuKensaData } = useFindOperationShugiQuery({
        opId: operationId,
        type: "yuketsu-kensa",
        page: 0,
        itemRelatedOnly: "0",
    });

    if (!yuketsuKensaData) return (<div></div>)

    return (
        <div className="m-splitColumns__editor procedure">
            <div className="m-splitColumns__main">
                <div className="procedure__area">
                    <BasePointList name={"所定点数"}
                                   opShugis={opShugisOpration.operationShugis}
                                   type={"yuketsu"}
                                   handleStartRegister={handleStartRegister}
                                   handleUpdateKasan={handleUpdateKasan}/>

                    <WeightInput  />

                    <ShugiCheckList
                        name="検査"
                        items={ yuketsuKensaData.list }
                        opShguis={opShugisOpration.operationShugis}/>

                </div>
            </div>
        </div>)
}


const ShugiPage: React.FC = (props) => {
    let operationId = useOperationIdParam();
    let tab = useShugiTabParam();
    let navigate = useNavigate();

    const [ postOperationShugi, {isLoading: operationShugiIsLoading} ] = usePostOperationShugiMutation()
    const { data: opShugisOpration, isLoading} = useGetOperationShugiQuery({opId: operationId }, { refetchOnMountOrArgChange: true })

    const handleStartRegister = (type: ShugiType, isFullList: boolean) => {
        navigate(`/operation/${operationId}/shugis/${tab}/list/${type}`)
    };

    const handleUpdateKasan = (id: number) => (kasanId: number) => (checked: boolean) => {
        if (opShugisOpration?.operationShugis != null) {
            const newOpShugis = opShugisOpration?.operationShugis?.map(mapOrDefault<OperationShugiResource>( (e) => e.id === id )((opShugi) => {
                return {
                    ...opShugi,
                    kasans: opShugi.kasans.map( mapOrDefault<OperationKasan>( (kasan) => kasan.id === kasanId )((kasan) => ({ ...kasan, isUsed: checked })))
                }
            }))

            postOperationShugi({
                opId: operationId,
                body: {
                    opId: operationId,
                    opShugis: newOpShugis
                }
            }).unwrap()
        }
    }

    const [ postOperationKasanOption, {isLoading: kasanOptionsIsLoading} ] = usePostOperationKasanOptionMutation();

    const handleKasanOption = (type: string) => (id: number|null) => {
        postOperationKasanOption({
            opId: operationId,
            body: {
                type:type,
                kasanOptionId: id
            }
        })
    }


    let shugiComponent;

    if (!opShugisOpration) {
        shugiComponent = (<></>);
    } else if (tab === "shujutsu") {
        shugiComponent = (<OperationShugiShujitsuList
            opShugisOpration={opShugisOpration}
            handleStartRegister={handleStartRegister}
            handleUpdateKasan={handleUpdateKasan}
            handleUpdateOption={handleKasanOption}
        />);
    } else if (tab === "masui") {
        shugiComponent = (<OperationShugiMasuiList
            opShugisOpration={opShugisOpration}
            handleStartRegister={handleStartRegister}
            handleUpdateKasan={handleUpdateKasan}
            handleUpdateOption={handleKasanOption}
        />);
    } else if (tab === "yuketsu") {
        shugiComponent = (<OperationShugiYuketsuList
            opShugisOpration={opShugisOpration}
            handleStartRegister={handleStartRegister}
            handleUpdateKasan={handleUpdateKasan}
            handleUpdateOption={handleKasanOption}
        />);
    } else {
    }

    const handleSelectShugiTab = (value: ShugiTabList) => {
        navigate(`/operation/${operationId}/shugis/${value}`)
    }

    return (
            <React.Fragment>

                <Box marginTop={"40px"} display={"flex"} width={"100%"} justifyContent={"center"}>
                    <Box width={"400px"}>
                        <ShugiTab value={tab} onSelectTab={ handleSelectShugiTab } />
                    </Box>
                </Box>

                { shugiComponent }

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading ||operationShugiIsLoading || kasanOptionsIsLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

            </React.Fragment>
    );
};

export default ShugiPage;