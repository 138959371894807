import {useOperationIdParam} from "../useOperationIdParameter";
import {OperationShugiResource, useGetOperationShugiQuery} from "../../store/api";

const opShugiSortFn = (a: OperationShugiResource, b: OperationShugiResource) => a.order - b.order;

export const useOpShugiListByTypeHook = (type: string ) => {
    const opId = useOperationIdParam();

    const { data } = useGetOperationShugiQuery({
        opId: opId
    });

    if (data == null)
        return null;

    return data
        .operationShugis
        .filter(e => e.type === type)
        .sort( opShugiSortFn )
};

export const useOpShugiByIdHook = (id: number) => {
    const opId = useOperationIdParam();

    const { data } = useGetOperationShugiQuery({
        opId: opId
    });

    if (data == null)
        return null;

    return data.operationShugis.filter( (opShugi) => opShugi.id == id );
}

export const opShugiQueryHooks = {
    useOpShugiByIdHook,
    useOpShugiListByTypeHook
}

export const opShugiCommandHooks = {

}