import React, {FC, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import BasicInfoForm from "./BasicInfoForm";
import {AppDispatch} from "../../app/store";
import {resetOperation, selectEditedOperation, selectSelectedOperation} from "../../app/slices/operationSlice";
import {OperationResult, usePostOperationApiMutation} from "../../store/api";
import dayjs from "dayjs";
import {EditorNav} from "./EditorNav";
import {useNavigate, useLocation} from "react-router-dom";
import {actions, popToastMessages} from "../../app/slices/ui/toastSlice";
import {Alert, Snackbar} from "@mui/material";

export const useLayoutLocationChange = (callback: () => void) => {
    const refCallback = useRef<undefined | (() => void)>()
    const location = useLocation()

    useLayoutEffect(() => {
        refCallback.current = callback
    }, [callback])

    // ロケーションに変更があったときに処理実行
    useLayoutEffect(() => {
        if (refCallback.current) {
            refCallback.current()
        }
    }, [location])
}
export const useLocationChange = (callback: () => void) => {
    const refCallback = useRef<undefined | (() => void)>()
    const location = useLocation()

    useEffect(() => {
        refCallback.current = callback
    }, [callback])

    // ロケーションに変更があったときに処理実行
    useEffect(() => {
        if (refCallback.current) {
            refCallback.current()
        }
    }, [location])
}

export const NewBasicInfoContainer: FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const [postOperationAPI] = usePostOperationApiMutation();
    const editedOperation = useSelector(selectEditedOperation);
    const toastMessages = useSelector(popToastMessages("basicInfo"));
    const navigate = useNavigate();

    useLayoutLocationChange( () => {
        dispatch(resetOperation())
    })

    if (editedOperation == null) {
        return (<></>);
    }

    const handleSave = async () => {
        const payload: OperationResult = Object.assign({}, editedOperation, {
            "doctorId": editedOperation.doctorId == 0 ? null :  editedOperation.doctorId,
            "departmentId": editedOperation.departmentId == 0 ? null :  editedOperation.departmentId,
            "operationDate": dayjs(editedOperation.operationDate).format('YYYY-MM-DD')
        });

        const result = await postOperationAPI({
            body: payload
        })

        if ("error" in result) {

            dispatch(actions.putToast({ kind: "basicInfo", text: "エラーが発生しました", severity: "error" }))

        } else if ("data" in result) {

            navigate(`/operation/${result.data.id}/detail`)
            dispatch(actions.putToast({ kind: "basicInfo", text: "新規作成しました", severity: "success" }))
        }

    }

    return (
            <React.Fragment>
                <BasicInfoForm onSave={handleSave} editedOperation={editedOperation}/>
                { toastMessages.length > 0 && toastMessages.map( (message,o) => (
                    <Snackbar
                        open={true}
                        anchorOrigin={{ vertical: 'top', horizontal: "center" }}
                        key={o}
                        sx={{ marginTop: 10 }} >
                        <Alert severity="success" sx={{ width: '100%' }}>
                            {message.toastText}
                        </Alert>
                    </Snackbar>)
                )}
            </React.Fragment>)
}