import {FC, ReactNode} from "react";

type Param = { name: string, };

export type FeatureFlag = "INSURANCE";

export const FeatureFlagF: (name: string) => (render: () => ReactNode ) => ReactNode | null = (name: string) => (render: () => ReactNode ) => {
    if ((`${process.env.REACT_APP_ENABLED_FEATURES}` ?? "").split(';').indexOf(name) > 0) {
        return render();
    } else {
        return null;
    }
}

export const FeatureFlag: FC<{name: string}> = (props) => {
    if ((`${process.env.REACT_APP_ENABLED_FEATURES}` ?? "").split(';').indexOf(props.name) > 0) {
        return (<>{props.children}</>);
    } else {
        return (<></>);
    }
}
