import React, {FC, RefObject, SetStateAction, Dispatch, useEffect, useRef, useState} from "react";
import {
    Alert, AlertTitle, Backdrop,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, Grid, IconButton,
    Input, List, ListItem, Step, StepLabel, Stepper, Typography
} from "@mui/material";
import {isOpen, modalActions} from "../../app/slices/modalSlice";
import {useDispatch, useSelector} from "react-redux";
import {
    usePostOperationImportMutation,
    useValidateOperationCsvMutation
} from "../../store/api";
import {Box} from "@mui/system";
import {AttachFile, CloudUploadOutlined} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

const FileSelectionStep : FC<{
    onSelectFile: (file: File | null) => void
}> = ({ onSelectFile }) => {

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleDrop : React.DragEventHandler<HTMLDivElement>= (event) => {
        if ( (event.dataTransfer?.files?.length??0) > 0) {
            if (event.dataTransfer?.files[0].type == "text/csv") {
                onSelectFile((event.dataTransfer?.files ?? [])[0])
            }
        }

        event.preventDefault()
    }

    const handleFileSelected = () => {
        if ( (fileInputRef?.current?.files?.length??0) > 0) {
            onSelectFile((fileInputRef?.current?.files ?? [])[0])
        } else {
            onSelectFile(null)
        }
    }

    return (
        <>
            <Box
                onClick={ () => fileInputRef.current != null ? fileInputRef.current.click() : null }
                textAlign={"center"}
                alignContent={"center"}
                marginTop={5}
                fontSize={"large"}
                sx={{
                    "cursor": "pointer",
                    border: "3px dashed",
                    borderRadius: "5px",
                    padding: "10px"
                }}>

                <Box alignSelf={"center"} onDrop={handleDrop} onDragOver={ (e) => e.preventDefault() }>
                    <CloudUploadOutlined fontSize={"large"} sx={{ fontSize: "40pt" }}/>
                    <Typography>クリックしてCSVファイルを選択</Typography>
                </Box>

                <input type={"file"}
                       ref={fileInputRef}
                       onChange={handleFileSelected}
                       accept="text/csv"
                       style={{display:"none"}} />
            </Box>
        </>)
}

const FileValidationStep : FC<{
    selectedFile: File | null,
    handleReset: () => void,
    onValidated: ( result: boolean ) => void
}> = ({selectedFile, handleReset, onValidated}) => {

    const [ validateApi, { reset, isLoading, isSuccess, isError } ] = useValidateOperationCsvMutation();

    const [ errors, setErrors ] = useState<Array<{line:number|null; error_level: "error"|"warning"; message:string;}>|null>( null );

    useEffect(() => {
        if ( selectedFile == null ) return;

        const form = new FormData();

        form.append("csv_file", selectedFile);

        validateApi({
            body: form as any
        }).then( (response:any) => {
            if ("error" in response){
                const error: any = response.error;
                if (error.status == 422) {
                    console.log(error.data.errors)
                    setErrors(error.data.errors);
                } else {
                    setErrors([{ line: null, error_level: "error", message: error.data.message } ]);
                }
                onValidated(false);
            } else {
                setErrors(response.data.errors);
                onValidated(true);
            }
        });

    }, [selectedFile]);

    let listElement;
    if (errors == null) {
        listElement = (<></>);
    } else {
        if (errors.length == 0) {
            listElement = (<Alert severity={"success"}>
                エラーはありませんでした。
            </Alert>)
        } else {
            listElement = errors.map( ({ line, error_level, message}: { line: number|null; error_level: "error"|"warning"; message:string; }) => (
                <Alert severity={ error_level }>
                    <ListItem>
                        { line != null && (`${line}行目:`) } {message}
                    </ListItem>
                </Alert>
            ))
        }
    }

    return (
        <Box padding={1}>
            <Box margin={1}>
                    <Typography fontSize={"medium"} display={"inline-block"}>ファイル: </Typography>
                    <Typography fontSize={"medium"} display={"inline-block"} marginLeft={3}>
                        { selectedFile?.name ?? "" }
                    </Typography>
                    <IconButton onClick={handleReset}>
                        <DeleteIcon color={"error"}  />
                    </IconButton>
            </Box>
            <Box>
                <Typography fontSize={"large"}>
                    検証結果
                </Typography>

                { listElement }


            </Box>
        </Box>
    )
}

export const CsvImportModal: FC<{ dialogName: string }> = ({dialogName}) => {

    const open: boolean = useSelector(isOpen(dialogName));

    const dispatch = useDispatch();

    const [ selectedFile, setFile ] = useState<File|null>( null );

    const [ validated, setValidated ] = useState<boolean|null>( null );

    const [ errors, setErrors ] = useState<any|null>( null );

    const [ csvImportMutation, { reset, isLoading, isSuccess, isError } ] = usePostOperationImportMutation();

    const [ step, setStep ] = useState<number>( 0 );

        const handleReset = () => {
            reset();

            setStep (0);
            setFile(null);
            setValidated(null);
        }

        const handleClose = () => {
            dispatch(modalActions.close());

            handleReset();
        }

        const handleOnSelectFile = (file: File | null) => {
            setFile(file);
            
            if ((file != null)) {
                setStep (1);
            } else {
                setStep (0);
            }
        }

        const handleCsvImport = () => {
            if (selectedFile != null) {
                setStep (2);

                const form = new FormData();

                form.append("csv_file", selectedFile);

                setErrors(null);

                csvImportMutation({
                    body: form as any
                }).then( (response:any) => {
                    if ("error" in response){
                        const error: any = response.error;
                        if (error.status == 422) {
                            setErrors(error.data);
                        } else {
                            setErrors(error.data);
                        }

                        setStep (4);
                    } else {
                        setStep (3);
                    }

                });
            }
        }

        return (

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">

                <DialogTitle id="alert-dialog-title">CSVインポート</DialogTitle>

                <DialogContent>
                    <Stepper activeStep={step} >

                        <Step key={"label1"}
                              completed={ step > 0 } >
                            <StepLabel >ファイルを選択</StepLabel>
                        </Step>

                        <Step key={"label2"}
                              completed={ step > 1  }>
                            <StepLabel error={validated === false}>ファイル検証</StepLabel>
                        </Step>

                        <Step key={"label3"}
                              completed={ step > 2 } >
                            <StepLabel error={step == 4}>反映</StepLabel>
                        </Step>

                        <Step key={"label4"}
                              completed={ step == 3 }>
                            <StepLabel >完了</StepLabel>
                        </Step>

                    </Stepper>

                    { step == 0 && (
                        <FileSelectionStep onSelectFile={handleOnSelectFile} />
                    )}

                    { step == 1 && (
                        <FileValidationStep selectedFile={selectedFile} handleReset={handleReset} onValidated={ (v)=> setValidated(v) } />
                    )}

                    { step == 3 && (
                        <Box padding={2}>
                            <DialogContentText id="alert-dialog-description">
                                <AlertTitle >成功</AlertTitle>
                                <Alert severity={"success"}>手術情報を取り込みました。</Alert>
                            </DialogContentText>
                        </Box>
                    )}

                    { step == 4 && (
                        <Box padding={2}>
                            <DialogContentText id="alert-dialog-description">
                                <AlertTitle >取り込みに失敗しました</AlertTitle>
                                <Alert severity={"error"}>{ errors?.message ?? "" }</Alert>
                            </DialogContentText>
                        </Box>
                    )}

                </DialogContent>

                <Divider />

                <DialogActions>
                    <Button onClick={handleClose} color={"secondary"}>キャンセル</Button>
                    { (step == 1 && validated == true) && (<Button onClick={ () =>{ handleCsvImport() }} >実行</Button>) }
                    { (step >= 3 ) && (<Button onClick={ () => handleClose() } >閉じる</Button>) }
                </DialogActions>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={ isLoading }>
                    <CircularProgress color="inherit" />
                </Backdrop>

            </Dialog>);
    }