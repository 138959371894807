import React, {FC, useEffect, useRef, useState} from "react";
import {usePostOperationItemWithJancodeMutation} from "../../store/api";
import {TextField, Typography} from "@mui/material";

/**
 * 入力された文字列がjancodeかを判別する
 *
 * @param {string} jancode
 * @return {boolean} Jancodeの場合はTrue
 */
const satisfiesJanCode = (jancode: string) => {
    if (!jancode.match(/^([0-9]{8}|[0-9]{13})$/)) {
        return false;
    }

    return jancode.length === 13 || jancode.length === 8
}

const satisfiesGS1Code = (code: string) => code.startsWith("/");

type GS1Code = {
    gtin14: {
        indicator: string,
        janCode: string
    }
    limitDate: string,
    quantity?: string,
    lotNo: string
};

type GS1CodeFragment = { [K in keyof GS1Code]?: GS1Code[K]; };

const cutJancode: (left: string) => [GS1CodeFragment, string] =
    (left: string) => [
        {
            gtin14: {
                indicator: left.substr(0,1),
                janCode: left.substr(1,13)
            },
        },
        left.substr(14)
    ];


const cutLimitDate: (left: string) => [GS1CodeFragment, string] =
    (left: string) => {
        const limitDateStr = left.substr(0,6);
        return [{ limitDate: limitDateStr }, left.substr(6)];
    }

/**
 * 可変長エリアを切り出す
 *
 * @param max
 */
const cutCode = (max: number) => (left: string) => {
    let i = 0;
    let drop = 0;

    // 読み進める
    for (; i < max && left[i] !== "/" && i < left.length; i++) {}

    // 読み捨てる
    if (left[i] === "/") drop = 1;

    return [left.substr(0, i), left.substr(i + drop)];
}

const cutQuantity: (left: string) => [GS1CodeFragment, string] = (code: string) => {
    const [quantity, left] = cutCode(8)(code)
    return [{quantity: quantity}, left];
}

const cutLotNo: (left: string) => [GS1CodeFragment, string] = (code: string) => {
    const [lotNo, left] = cutCode(20)(code)
    return [{lotNo: lotNo}, left];
}

const parseAI = (left: string) => {
    const supported_ais = ["01", "17", "30", "10", "21"];
    for (let ai of supported_ais) {
        if (left.startsWith(ai)) {
            return [ai, left.substr(ai.length)];
        }
    }

    throw "ai parse error";
}

const parseGS1Code = (s:string) => {
    let current  = s;
    let result: GS1Code = {
        gtin14: {
            indicator: "",
            janCode: "",
        },
        lotNo: "",
        limitDate: "",
        quantity: "",
    };

    while (current.length > 0) {
        if (current.startsWith("/")) {
            current = current.substr(1)
            continue;
        }
        let [ai, left] = parseAI(current)
        let code = null;

        if (current.startsWith("01")) {
            [code, current] = cutJancode(left);
            result = { ...result, ...code };
        } else if (current.startsWith("17")) {
            [code, current] = cutLimitDate(left);
            result = { ...result, ...code };
        } else if (current.startsWith("30")) {
            [code, current] = cutQuantity(left);
            result = { ...result, ...code };
        } else if (current.startsWith("10") || ai.startsWith("21")) {
            [code, current] = cutLotNo(left);
            result = { ...result, ...code };
        } else {
            throw "ai not supported";
        }
    }

    return result
}

const useTimeout = (millsec: number) => {
    const [ handler, setHandler ] = useState<null|number>(null);

    const cancel = () => {
        if (handler == null) return;

        window.clearTimeout(handler);

        setHandler(null);
    }

    const start = (f: ()=> void) => {
        cancel();

        setHandler( window.setTimeout(() => {
            f();
        }, millsec));
    }

    return {
        start,
        cancel
    }
}

export const JanCodeInput: FC<{operationId: number}> = ({operationId}) => {
    const [janCode, setString] = useState("");
    const [isError, setError]= useState(false);
    const [ postJancode ] = usePostOperationItemWithJancodeMutation();
    const watchdogTimer   = useTimeout(1000);

    const janCodeRef = useRef(janCode);
    janCodeRef.current = janCode;

    const handleRegister = (janCode: string) => {
        console.log(janCode)

        watchdogTimer.cancel();

        if (janCode.length == 0) return;

        let gs1Code: GS1Code | null = null;
        let searchJanCode = null

        try {
            gs1Code = parseGS1Code(janCode)
            searchJanCode = gs1Code.gtin14.janCode

        } catch ( e ) {
            searchJanCode = janCode
        }

        if (gs1Code) {
            gs1Code = parseGS1Code(janCode)
            postJancode({
                body: {
                    opId: operationId,
                    jancode: gs1Code.gtin14.janCode,
                    quantity: 1,
                    lotLimit: [ { lot: gs1Code.lotNo, limitDate: gs1Code.limitDate } ]
                }
            }).then((re)=> {
                if ("error" in re) {
                    setError(true)
                } else {
                    setError(false)
                }
                setString("")
            });
        } else {
            postJancode({
                body: {
                    opId: operationId,
                    jancode: searchJanCode,
                    quantity: 1,
                    lotLimit: []
                }
            }).then((re)=> {
                if ("error" in re) {
                    setError(true)
                } else {
                    setError(false)
                }
                setString("")
            });
        }
    }

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key !== "Enter") {
            watchdogTimer.start( () => handleRegister(janCodeRef.current) );
        } else {
            watchdogTimer.cancel();
            handleRegister(janCodeRef.current)
        }
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setString(e.target.value ?? "")
    }

    return (
        <React.Fragment>
            <TextField
                   variant="standard"
                   placeholder="4912345678901"
                   size={ "medium" }
                   value={janCode}
                   fullWidth={true}
                   sx={ {
                       '& .MuiInput-underline:hover:not(.Mui-disabled):before' : {
                           borderBottomColor: 'rgba(3,76,140,0.5)',
                       },
                       '& .MuiInput-underline:after' : {
                           borderBottomColor: '#034c8c',
                       },
                       // ;
                   } }
                   inputProps={{
                       style: {
                           fontSize: 24,
                           borderBottomColor: "white",
                       }
                    }}
                   onKeyDown={ handleOnKeyDown }
                   onChange={ handleOnChange }/>
            {isError ? (<Typography color={"error"}>商品情報と一致しません</Typography>) : "" }
        </React.Fragment>)
}
