import React, {FC, useEffect, useRef, useState} from "react";
import {OpRegistrableItemResource, useFindItemsQuery, usePostOperationItemMutation} from "../../store/api";
import {
    Backdrop, Button, CircularProgress, Grid,
    Input,
    Pagination, Stack, SxProps, Table, TableBody, TableCell, TableHead, TableRow,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {useOperationIdParam} from "../../hooks/useOperationIdParameter";
import {useNavigate} from "react-router-dom";
import {SearchTextInput} from "../atoms/TextFields";
import {Box} from "@mui/system";

const ItemTab:FC<{ value: string, setValue: (v:string) => void}> = ({value, setValue}) => {
    return (
        <ToggleButtonGroup
            color="primary"
            value={value}
            exclusive
            onChange={(v, value) => setValue(value ?? "item")}
            aria-label="Platform"
            fullWidth
        >
            <ToggleButton value="item">医療材料</ToggleButton>
            <ToggleButton value="yakuhin">薬品</ToggleButton>
        </ToggleButtonGroup>
    )
}

export const SelectOpItemPage: React.FC = (props) => {
    const tableRef = useRef<HTMLDivElement>(null);
    let operationId = useOperationIdParam();
    const navigate = useNavigate();
    const [ createOpItemMutation ] = usePostOperationItemMutation();

    useEffect( () => {
        let e = function() {
            let e= document.createElement("div");
            e.style.visibility="hidden";
            e.style.overflow="scroll";
            document.body.appendChild(e);
            let t=e.offsetWidth-e.clientWidth;
            document.body.removeChild(e)
            return t;
        }();

        if (tableRef.current != null) {
            tableRef.current.style.setProperty("--scrollbar-width","".concat((-1 * e).toString(), "px"))
        }
    }, [tableRef.current]);

    // アイテム一覧
    const [states, setState] = useState({
        page: 0,
        searchText: "",
        tab: "item"
    });

    const setPage = (page: number) => setState( {...states,  "page": page })

    const setTab = (tab: string) => {
        setState( {...states, "tab": tab, "page": 0 })
    }

    const setSearchText = (searchText: string) => {
        setState( {...states,
            page: 0,
            "searchText": searchText })
    }

    const {data, refetch, isFetching } = useFindItemsQuery({
        name: states.searchText,
        offset: states.page,
        type: states.tab
    })

    const items = data?.items ?? []

    const onPaginationChange = (selected: number) => {
        setPage(selected - 1)
    }

    const handleRegister = (item: OpRegistrableItemResource) => {
        createOpItemMutation({
            body: {
                ...item,
                opId: operationId,
                quantity: 1,
                lotLimit: []
            }
        })

        // アイテム一覧に戻る
        navigate(`/operation/${operationId}/op_item`);
    }

    const handleBack = () => {
        navigate(`/operation/${operationId}/op_item`);
    }

    if (data == null)
        return (<></>)

    const tableStyle = (varialnt: "header" | "body") => {
        if (varialnt =="header") {
            return {
                "border": "2px solid #034c8c",
                "borderBottom": "none",
                "borderCollapse": "separate",
                "borderSpacing": 0,
                "tableLayout": "fixed",
                "width": "100%",
                "borderRadius": "5px 5px 0 0"
            } as SxProps
        } else {
            return {
                "border": "2px solid #034c8c",
                "borderTop": "none",
                "borderCollapse": "separate",
                "borderSpacing": 0,
                "tableLayout": "fixed",
                "width": "100%"
            } as SxProps;
        }
    }


    const sx={
        "color": "#034c8c",
        "fontSize": "14px",
        "fontWeight": "700",
        "lineHeight": "1.5",
        "padding": "20px 20px 22px",
        "textAlign": "center",
        "borderBottom": "2px solid #034c8c",
    }

    const clickableTableRow = {
        cursor: "pointer",
        ":Hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
    }

    const tableCellLeft = {
        ...sx,
        "borderLeft": "2px solid #034c8c"
    }


    return (
            <Box sx={{
                margin: "50px auto 50px",
                maxWidth: "1180px",
                display: "flex"
            }}>
                <Box >
                    <Button onClick={handleBack}>
                        <img src="../../images/icon_arrow_left.svg" alt="" width="19" height="34" decoding="async" />
                    </Button>
                </Box>
                <Box>
                    <Box width={350}>
                        <SearchTextInput
                            fullWidth
                            label={"名前で探す"}
                            onSearch={(text) => setSearchText( text )} />
                    </Box>

                    <Box></Box>

                    <Box marginTop={3} width={350}>
                        <ItemTab value={states.tab} setValue={setTab} />
                    </Box>

                    <Box marginTop={3} maxWidth={"1120px"}>
                        <Pagination
                            count={data.pages}
                            page={states.page + 1}
                            color="primary" onChange={ (e, page) => onPaginationChange(page) }/>
                    </Box>

                    <Box marginTop={2}
                         sx={{
                             textAlign: "center",
                             display: "flex",
                             maxWidth: "1120px",
                             justifyContent: "space-between",
                         }}>
                                <div className="m-table" ref={tableRef}>
                                    <Table sx={ tableStyle("header") } aria-label="caption table" >
                                        <TableHead>
                                            <TableRow >
                                                <TableCell sx={{ ...sx, width: 180 }} >JANコード</TableCell>
                                                <TableCell sx={{ ...tableCellLeft, width: 250 }} >メーカー</TableCell>
                                                <TableCell sx={{ ...tableCellLeft, textAlign: "left"}} >
                                                    商品コード<br />
                                                    物品名称<br />
                                                    規格<br />
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>

                                    <div className="m-table__data"
                                         style={{
                                             height: "auto",
                                             maxHeight: "calc(100vh - 450px)" } as React.CSSProperties}>
                                        <Table sx={tableStyle("body")} aria-label="caption table">
                                            <TableBody>
                                                { items.map ((item: OpRegistrableItemResource, i) =>
                                                    <TableRow sx={clickableTableRow}
                                                              key={ item.itemId ?? item.yakuhinId }
                                                              onClick={ () => handleRegister(item) }>
                                                        <TableCell  sx={{...sx, width: 180 }} >{ item?.JAN }</TableCell>
                                                        <TableCell sx={{ ...tableCellLeft, width: 250 }} >{ item?.makerName}</TableCell>
                                                        <TableCell sx={{ ...tableCellLeft, textAlign: "left"}}>
                                                            { item?.itemCode ? item?.itemCode : item.medieCode }<br />
                                                            { item?.name}[{item?.yomi}]<br />
                                                            { item?.kikaku}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </div>
                            </div>
                    </Box>

                    <div className="useitem__areainner" style={{ maxWidth: "none" }}>
                    </div>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isFetching}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                </Box>

            </Box>);
}
