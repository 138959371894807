import React, {FC} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {
    emitNow, SearchPeriodLast7Day, SearchPeriodLastMonth, SearchPeriodThisMonth,
    OperationSearchConditionSelector,
    setLast7Day,
    setLastMonth,
    setThisMonth
} from "../app/slices/operationSearchConditionSlice";
import {Box, ToggleButton, ToggleButtonGroup} from "@mui/material";


export const DateSearchToggleBar: FC<{ className: "list" | "m-splitColumns" }> = ({className, children}) => {
    const condition = useSelector(OperationSearchConditionSelector)
    const dispatch = useDispatch();

    const onChange = (e: string) => {
        switch (e) {
            case SearchPeriodLast7Day:
                dispatch(setLast7Day(emitNow()))
                break;

            case SearchPeriodThisMonth:
                dispatch(setThisMonth(emitNow()))
                break;

            case SearchPeriodLastMonth:
                dispatch(setLastMonth(emitNow()))
                break;
        }
    }

    return (
        <>
            <Box maxWidth={400}>
                <ToggleButtonGroup
                    value={condition.period }
                    exclusive
                    onChange={ (e, t) => onChange(t) }
                    fullWidth
                    color={"primary"} >
                    <ToggleButton value={SearchPeriodLast7Day}  >直近７日</ToggleButton>
                    <ToggleButton value={SearchPeriodThisMonth} >今月</ToggleButton>
                    <ToggleButton value={SearchPeriodLastMonth} >先月</ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </>
        );
}
