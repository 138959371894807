
import React, {FC, MouseEventHandler, PropsWithChildren, useState} from 'react';

import {MList, MListFilter, MListItemColumn, MListItemDate} from "../../components/atoms/MListItemColumn";
import {
    OperationSearchResult, useOperationListApiQuery,
} from "../../store/api";
import {Box, Container, Grid, Icon, IconButton, Pagination, Stack, Tooltip} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {operationIdSelector, useSelectOperation} from "../../app/slices/operationSlice";
import {modalActions} from "../../app/slices/modalSlice";
import {DeleteOperationModal} from "../modals/DeleteOperationModal";
import {OperationSearchConditionSelector, toggleSort} from "../../app/slices/operationSearchConditionSlice";
import {NavigativeMListItem} from "../atoms/NavigativeMListItem";

const filterBalanceFigure = (n: number) => (n < 0 ? "-" : "+") + n.toLocaleString()


const FullWidthOperationListColumns : FC<{operation: OperationSearchResult}> = ({operation}) => {
    return (
        <Grid container direction={"row"} marginTop={1} columns={12}>
            <MListItemColumn xs={2} sm={2} md={2}  lg={1} title={"診療科"} data={operation.departmentName} />
            <MListItemColumn xs={2} sm={2} md={2}  lg={1} title={"執刀医"} data={operation.doctorName} />
            <MListItemColumn xs={2} sm={2} md={2}  lg={1} title={"OPID"} data={operation.externalOpId} />
            <MListItemColumn xs={4} sm={5} md={5}  lg={3} title={"メイン術式"} data={operation.shugi} />
            <MListItemColumn xs={2} sm={2} md={2}  lg={1} title={"収入"} data={filterBalanceFigure(operation.totalIncome)} />
            <MListItemColumn xs={2} sm={2} md={2}  lg={1} title={"支出"} data={filterBalanceFigure(operation.totalCost).toLocaleString() } />
            <MListItemColumn xs={2} sm={2} md={2}  lg={1} title={"収支情報"} data={filterBalanceFigure(operation.totalIncome + operation.totalCost)} />
        </Grid>
    )
}

const SideOperationListColumns : FC<{operation: OperationSearchResult}> = ({operation}) => {
    return (
        <Grid container direction={"row"} marginTop={1} columns={12}>
            <MListItemColumn xs={4}  md={2} title={"診療科"} data={operation.departmentName} />
            <MListItemColumn xs={4}  md={2} title={"執刀医"} data={operation.doctorName} />
            <MListItemColumn xs={4}  md={2} title={"OPID"} data={operation.externalOpId} />
            <MListItemColumn xs={5}  md={4} title={"メイン術式"} data={operation.shugi} />
            <MListItemColumn xs={3}  md={2} title={"収支情報"} data={filterBalanceFigure(operation.totalIncome + operation.totalCost)} />
        </Grid>
    )
}



const OperationList: React.FC<PropsWithChildren<{
    className: "list" | "m-listItem",
    isSmall: Boolean|undefined}>> = ({className, isSmall}) => {

    console.log("OperationList")

    const condition = useSelector(OperationSearchConditionSelector);

    const { data} = useOperationListApiQuery({
        keyword: condition.searchText,
        startDate: condition.fromDate,
        endDate: condition.toDate,
        isAsc: condition.sortAsc
    })

    const pageWindow = 20.0;

    const [ page, setPage ] = useState(1)

    const dispatch = useDispatch();

    const selectedId = useSelector(operationIdSelector);

    const [deleteTargetId, setDeleteTargetId] = useState<number>(0);

    const onSortChange: MouseEventHandler = (event) => { dispatch(toggleSort()); }

    if ((page - 1) * 20 > (data?.length ?? 0)) {
        setPage(1)
    }

  return (
        <MList className={className}>

            <MListFilter className={className} onClick={onSortChange} isAsc={condition.sortAsc} />

            <Pagination count={ Math.ceil((data?.length ?? 0) / pageWindow) }
                        variant="outlined"
                        shape="rounded"
                        page={ page }
                        onChange={ (_, page) => setPage( page) }
                        sx={{
                            mt: 3,
                            mb: 3,
                            "button": {
                                borderColor: "rgb(3, 76, 140)",
                                borderWidth: "2px"
                            }
                        }}/>

            {data?.filter((e, i) => ((page-1) * pageWindow) <= i && i < ((page-1) * pageWindow + pageWindow) ).map((operation) => (
                <NavigativeMListItem
                    operationId={operation.id}
                    className={selectedId === operation.id ? "active" : "" }
                    key={operation.id + className}>

                    <MListItemDate date={operation.operationDate}></MListItemDate>

                    <Container>

                    </Container>
                    <Stack direction={"row"}>
                        { className === "list" ?
                            (<FullWidthOperationListColumns operation={operation} />) :
                            (<SideOperationListColumns operation={operation} />)
                        }

                        <Box width={"50px"}>
                            <Tooltip title={operation.isEmpty ? "" : "利用物品登録済のため削除できません" }>
                                <IconButton color={ operation.isEmpty ? "error" : "default" } edge={"end"}
                                            onClick={ (event) => {
                                                event.preventDefault();

                                                setDeleteTargetId(operation.id);
                                                dispatch(modalActions.open("deleteOperation"))
                                            }}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Stack>
                </NavigativeMListItem>
            ))}

            <Pagination count={ Math.ceil((data?.length ?? 0) / pageWindow) }
                        variant="outlined"
                        shape="rounded"
                        page={ page }
                        onChange={ (_, page) => setPage( page) }
                        sx={{
                            mt: 3,
                            mb: 3,
                            "button": {
                                borderColor: "rgb(3, 76, 140)",
                                borderWidth: "2px"
                            }
                        }}/>

          <DeleteOperationModal dialogName={"deleteOperation"} targetId={deleteTargetId} />
      </MList>
  );
};

export default OperationList;
