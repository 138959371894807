import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {Navigate, useParams} from 'react-router-dom';

const Auth0LoginNavigation: React.FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const {orgId} = useParams<{orgId:string}>();

  if ( isAuthenticated ) {
    return <Navigate replace to='/home' />;
  } else {
    if (orgId == null || orgId == "") {
      loginWithRedirect({});
    } else {
      loginWithRedirect({
        organization: orgId
      });
    }

    return <div/>;
  }
};

export default Auth0LoginNavigation;
