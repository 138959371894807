import {configureStore} from '@reduxjs/toolkit';

import operationReducer from '../app/slices/operationSlice';
import opItemsReducer from '../app/slices/opItemSlice';
import dateReducer from './slices/operationSearchConditionSlice';
import {api} from "../store/api";
import {toastReducer} from "./slices/ui/toastSlice";
import {modalReducer} from "./slices/modalSlice";

export const reducer = {
  operation: operationReducer,
  opItems: opItemsReducer,
  date: dateReducer,

  toast: toastReducer,
  modal: modalReducer,

  [api.reducerPath]: api.reducer,
};


export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
