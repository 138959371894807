import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {useDispatch} from "react-redux";
import {operationSlice} from "../operationSlice";
import {AlertColor} from "@mui/material";

type ToastType = {toastText: string, kind: string, id: number, severity: AlertColor };

type StateType = {
    toasts: Array<ToastType>;
    id: number;
};

export const initialState: StateType = {
    toasts: [],
    id: 0
};

const slice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        putToast(state, action: PayloadAction<{ text: string, kind: string, severity: AlertColor }>) {
            return {
                toasts: [...state.toasts, {
                    id: state.id + 1,
                    toastText: action.payload.text,
                    kind: action.payload.kind,
                    severity: action.payload.severity
                }],
                id: state.id + 1
            }
        },

        clearToast(state, action: PayloadAction<{ id: number }> ) {
            state.toasts = state.toasts.filter( (toast) => toast.id != action.payload.id);
            return state
        },

    },
});

export const actions = slice.actions;

export const popToastMessages = (kind: string) => (state: RootState) => {
    const dispatch = useDispatch();

    const toasts = state.toast.toasts.filter((toast: ToastType) => toast.kind == kind );
    if (toasts.length > 0) {
        window.setTimeout( () => {
            toasts.forEach( (toast) => {
                dispatch(actions.clearToast({ id: toast.id }));
            })
        }, 2000);
    }

    return toasts;
}

export const toastReducer = slice.reducer;
