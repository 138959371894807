import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

import dayjs from "dayjs";
import {
  useFindOperationShugiQuery,
  useOperationListApiQuery,
  useOperationPrintApiMutation
} from "../../store/api";
import {useSelector} from "react-redux";
import {useState} from "react";
import {default as fileDownload} from "js-file-download";

export type TDateSearchPeriod = "last-7-day" | "last-month" | "this-month" | "custom";

export type TDateSearchCondition = {
  period: TDateSearchPeriod,
  fromDate: string,
  toDate: string,
  searchText: string,
  sortAsc: boolean
}

export const SearchPeriodLast7Day: TDateSearchPeriod = "last-7-day";
export const SearchPeriodLastMonth: TDateSearchPeriod = "last-month";
export const SearchPeriodThisMonth: TDateSearchPeriod = "this-month";
export const SearchPeriodCustom: TDateSearchPeriod    = "custom";

export const emitNow = () => dayjs().startOf("days").format('YYYY-MM-DD').toString()

const mapLast30Day = (date: string) => ({
  period: SearchPeriodLast7Day,
  fromDate: dayjs(date).add(-7, 'day').format('YYYY-MM-DD').toString(),
  toDate: dayjs(date).format('YYYY-MM-DD'),
})

const mapLastMonth = (date: string) => ({
  period: SearchPeriodLastMonth,
  fromDate: dayjs(date).startOf("month").add(-1, 'month').format('YYYY-MM-DD').toString(),
  toDate: dayjs(date).startOf("month").add(-1, 'day').format('YYYY-MM-DD').toString(),
})

const mapThisMonth = (date: string) => ({
  period: SearchPeriodThisMonth,
  fromDate: dayjs(date).startOf("month").format('YYYY-MM-DD').toString(),
  toDate: dayjs(date).startOf("month").add(1, 'month').add(-1, 'day').format('YYYY-MM-DD').toString(),
})

const mapFrom = (from: string) => (cond :TDateSearchCondition) => ({
  period: SearchPeriodCustom,
  fromDate: from,
  toDate: cond.toDate,
})

const mapTo = (to: string) => (cond :TDateSearchCondition) => ({
  period: SearchPeriodCustom,
  fromDate: cond.fromDate,
  toDate: to,
})

export const initialState: TDateSearchCondition = {
  searchText: "",
  sortAsc: false,
  ...mapLast30Day(emitNow())
}

// sessionStorageから復元する
export const makeInitialState: () => TDateSearchCondition
    = () => {
        const cond: string | null = sessionStorage.getItem("operation_condition");

        if (cond == null)
          return initialState;

        try {
          const condition = JSON.parse(cond);

          return {
            searchText: condition?.searchText ?? initialState.searchText,
            sortAsc:  condition?.sortAsc ?? initialState.sortAsc,
            period:  condition?.period ?? initialState.period,
            fromDate:  condition?.fromDate ?? initialState.fromDate,
            toDate:  condition?.toDate ?? initialState.toDate,
          };

        } catch (e) {
          return initialState;
        }
 }

const execSaveSession : (state: TDateSearchCondition) => TDateSearchCondition = (state: TDateSearchCondition) => {
  sessionStorage.setItem("operation_condition", JSON.stringify(state))

  return state;
}

export const operationSearchConditionSlice = createSlice({
  name: 'date',
  initialState: makeInitialState(),
  reducers: {

    setSearchText(state, action: PayloadAction<string>) {
      return execSaveSession({...state, searchText: action.payload})
    },
    toggleSort(state) {
      return execSaveSession({...state, sortAsc: !state.sortAsc})
    },

    setLast7Day(state, action: PayloadAction<string>) {
      return execSaveSession({ ...state, ...mapLast30Day(action.payload) })
    },

    setLastMonth(state, action: PayloadAction<string>) {
      return execSaveSession({ ...state, ...mapLastMonth(action.payload) })
    },

    setThisMonth(state, action: PayloadAction<string>) {
      return execSaveSession({ ...state, ...mapThisMonth(action.payload) })
    },

    setFromDate(state, action: PayloadAction<string>) {
      return execSaveSession({ ...state, ...mapFrom(action.payload)(state) })
    },

    setToDate(state, action: PayloadAction<string>) {
      return execSaveSession({ ...state, ...mapTo(action.payload)(state) })
    },

  },
});




export const useInsuranceInformationDownloadMutation = () => {
  const condition = useSelector(OperationSearchConditionSelector);

  const [api] = useOperationPrintApiMutation()

  const startDownload = () => {

    api({
      keyword: condition.searchText,
      startDate: condition.fromDate,
      endDate: condition.toDate,
      isAsc: condition.sortAsc
    }).then( (result: any) => {
      const data = result as {data : Blob}
      fileDownload(data.data, "operations.xlsx");
    } )
  };

  return {
    startDownload
  }

};

export const {
  setSearchText,
  toggleSort,
  setLast7Day,
  setLastMonth,
  setThisMonth,
  setFromDate,
  setToDate
} = operationSearchConditionSlice.actions;

export const OperationSearchConditionSelector = (state: RootState) => state.date;
export const DateBeforeSelector = (state: RootState) => state.date.fromDate;
export const DateAfterSelector = (state: RootState) => state.date.toDate;

export default operationSearchConditionSlice.reducer;
