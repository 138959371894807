import {Navigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {
    AppBar,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Toolbar,
    Typography
} from "@mui/material";
import {TitleLogo} from "../atoms/TitleLogo";
import React from "react";
import {RecepoAppBar} from "../atoms/RecepoAppBar";

export const LoggedInPage = () => {
    const url = new URL(window.location.href)

    const { logout } = useAuth0();

    const handleLogout = () => {
        logout();
    }

    if (url.searchParams.get("error") != null) {
        let message = "";
        if (url.searchParams.get("error") === "access_denied") {
            message = "ユーザーアカウントが登録されていないかまたは、アカウントが停止されています。"
        } else {
            message = "システムエラーが発生しました。"
        }

        return (<>
            <RecepoAppBar isSide={false} />

            <Container maxWidth={"md"}>

                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"ログインに失敗しました。"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography>
                                {message}お問い合わせはこちら<br />
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleLogout} autoFocus variant={"contained"}>
                            別のアカウントでログインする
                        </Button>
                    </DialogActions>
                </Dialog>

            </Container>
        </>);
    } else {
        return (<Navigate replace to={"/home"}></Navigate>)
    }
}