import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axios from 'axios';
import { READ_OP_ITEM } from '../../types';
import { useAuth0 } from '@auth0/auth0-react';

export const fetchAsyncGetItem = createAsyncThunk(
  'item/getItem',
  async (jan: number) => {
    const { getAccessTokenSilently } = useAuth0();
    const token = await getAccessTokenSilently();
    const res = await axios.get<READ_OP_ITEM[]>(
      `${process.env.REACT_APP_API_URL}/items/?jan=${jan}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  }
);

type StateType = {
    opItems: READ_OP_ITEM[]
};

export const initialState: StateType = {
  opItems: [],
};

export const operationSlice = createSlice({
  name: 'opItems',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getOperationsのGETが成功したら、その返り値のデータをoperationsのstateに代入
    builder.addCase(
      fetchAsyncGetItem.fulfilled,
      (state, action: PayloadAction<READ_OP_ITEM[]>) => {
        return {
          ...state,
          opItems: action.payload,
        };
      }
    );
  },
});

// export const { editedOperation, selectedOperation } = operationSlice.actions;
export const selectOpItems = (state: RootState) => state.opItems.opItems;
export default operationSlice.reducer;
