import React from 'react';
import {useAuth0} from "@auth0/auth0-react";

// import './theme/app.css';
import {Provider} from "react-redux";
import {store} from "../../app/store";
import {RecepoRouter} from "../routing/RecepoRouter";
import {sec} from "../../store/emptyApi";
import {mainTheme} from "../../theme/Theme";
import {ThemeProvider} from "@mui/material";

const App : React.FC = () => {
  console.time("APP");

  const { isLoading } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();

  // RTK QUeryにaccessTokenの取得関数を渡す
  sec.setAccessTokenSilently(getAccessTokenSilently);

  if (isLoading) {
    return <div></div>
  }

  const element = (
      <React.StrictMode>
          <Provider store={store}>
              <ThemeProvider theme={mainTheme}>
                  <RecepoRouter />
              </ThemeProvider>
          </Provider>
      </React.StrictMode>);

  console.timeEnd("APP");
  return element;
}

export default App;
