
import {createSlice, ThunkAction} from "@reduxjs/toolkit";
import {
    api,
    DeleteOperationShugiApiArg,
    OperationKasan,
    OperationShugiResource,
    ReorderOperationShugiApiArg
} from "../../store/api";

type StateType = {
};

/** selectors */

/** mutations */

export const optimisticUpdateEndpoint = <Req, Res> (update: (request: Req) => ThunkAction<any, any, any, any>) => (done: (data: Res) => void) => (failed: (e: Error) => void) => (endpoint: any) => {
    endpoint.onQueryStarted = async (request: Req, { dispatch, queryFulfilled }: {dispatch: (re: ThunkAction<any, any, any, any>) => void, queryFulfilled: Promise<any> }) => {
        dispatch( update(request) );

        try {

            const { data: updatedPost } = await queryFulfilled;

            done(updatedPost)

        } catch (e) {

            failed(e as Error)

        }
    }
}

const getOperationShugiUpdateThunkAction = (request: ReorderOperationShugiApiArg) => {
    return api.util.updateQueryData('getOperationShugi', {opId: request.opId} , (draft) => {
        draft.operationShugis = draft.operationShugis.map( (opShugis) => {
            for (const f of request.body) {
                if ( opShugis.id == f.opShugiId ) {
                    return { ...opShugis, order: f.order }
                }
            }
            return { ...opShugis }
        })
    })
}

export const CustomReorderOperationShugiAPI = optimisticUpdateEndpoint( getOperationShugiUpdateThunkAction )( () => {} )( () => {} )



/** actions */


const opShugiSlice = createSlice({
    name: "opShugi",
    initialState: () => {
        return {

        } as StateType;
    },
    reducers: {
    },
});




